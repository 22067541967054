import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function DragAndDrop({ finalSpaceCharacters, selected, setSelected, onChange }) {
  const [characters, updateCharacters] = useState(finalSpaceCharacters);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
    onChange(items);
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable direction="horizontal" droppableId="characters">
        {(provided) => (
          <ul
            style={{ display: "flex" }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {characters.map((name, index) => {
              return (
                <Draggable
                  key={name}
                  draggableId={name}
                  index={index}
                  style={{ margin: 8 }}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        onClick={() => setSelected(name)}
                        style={{
                          border: "1px solid #ccc",
                          margin: 4,
                          backgroundColor: selected==name? "#222":"#fff",
                          borderRadius: 4,
                          color: selected == name? '#fff': '#000',
                          padding: "0 12px",
                          fontSize: 18,
                        }}
                      >
                        {name}
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragAndDrop;
