import { createStore, combineReducers } from "redux";
import usersReducer from "../layouts/authentication/usersReducer";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import BrandReducer from "layouts/brands/BrandReducer";
import changelogReducer from "components/ChangeLog/Reducer";

const appReducer = combineReducers({
  usersReducer:usersReducer,
  brandReducer: BrandReducer,
  changelogReducer:changelogReducer,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["usersReducer","changelogReducer"],
};
const persistedReducer = persistReducer(persistConfig, appReducer);
// Redux store
const store = createStore(
  persistedReducer,
  {},
  // compose(
  //   applyMiddleware(createLogger(), thunk),
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
);

let persistor = persistStore(store);
export { store, persistor };
