import { actionTypes } from "../../constants/ActionTypes";

export const updateUserSignin = (status) => ({
  type: actionTypes.UPDATE_SIGNIN_STATUS,
  payload: status,
});

export const updateUserData = (status) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: status,
});

export const updateAuthToken = (token) => ({
  type: actionTypes.UPDATE_AUTH_TOKEN,
  payload: {
    token: token,
  },
});

export const updateLastAPIResponse = (res) => ({
  type: actionTypes.UPDATE_LAST_API_RESPONSE,
  payload: res,
});
