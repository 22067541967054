import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { doLogin } from "layouts/authentication/API";
import axios from "axios";
import { appConstants } from "constants/AppConstants";
import Select from "react-select"
import { store } from "store";
function Invoice(props) {
  const [showToast, setShowToast] = useState(false)
  const [master, setMaster] = useState(
    props.masterData[props.type.category[0].value]
  );
  const [category, setCategory] = useState(props.type.category[0])
  const [currentStatus, setCurrentStatus] = useState("");
  const [errStatus, setErrStatus] = useState("");
  const [name,setName] = useState("")
  const [password, setPassword] = useState("")
  const [fromIndex, setFrom] = useState()
  const [toIndex, setTo] = useState()
  const [selectedIndex, setSelected] = useState("")
  let path = props.label.split("/")

  const userName = store.getState().usersReducer?.userData?.full_name?.first_name+" "+store.getState().usersReducer?.userData?.full_name?.last_name;

  const downloadFile = () => {
    var config = {
      method: "get",
      url: `https://super-lambda.flyotech.com/super-lambda-api-downloadS3FilesLambdaFunction-mAsosFEqMWTe?type=${props.type.value}&file_name=${path[path.length-1]}`,
      headers: {
        api_key: "1234!@#$%&",
      },
    };
    return axios(config)
  }

  const generateLog = async (success,username) => {
    let data = {
      updateStack: [category.value],
      update: {
        sheet: category.label,
        from: fromIndex > 1 ? fromIndex - 2 : 0,
        to: toIndex > 1 ? toIndex - 2 : 5000,
        selectedIndex: selectedIndex,
      },
      config: {
        base_url: appConstants.API_BASE_URL_PROD,
        base_url_mini: appConstants.API_BASE_MINI_PROD,
        user: username+"_"+userName,
      },
      updateDetails: {
        user: username+"_"+userName,
        uploadTime: new Date(),
        uploadSucceeded: success ? "Yes" : "No",
        csvFileName: category.value,
        fileType: props.type.value,
        uiltFile: `${category.util}_util`,
      },
    };
    var str = JSON.stringify(data);

    const blob = new Blob([str], {
      type: "application/json",
    });

    var dataConf = new FormData();
    dataConf.append("data", blob);
    let d = new Date();
    let dateFormatted =
      d.getFullYear() +
      "-" +
      (d.getMonth() > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)) +
      "-" +
      (d.getDate() > 9 ? d.getDate() : "0" + d.getDate());
    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=admin_log&file_name=${dateFormatted}/frontend/production/${
        props.type.value
      }_${new Date()}`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: dataConf,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const postToS3 = (file,username) => {
    setCurrentStatus("Fetching csv file....");
    var data = new FormData();
    data.append("data", file);

    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=${category.type}&file_name=${category.label}_prod`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: data,
    };
    setCurrentStatus("Uploading csv file....");
    let success = false;
    axios(config)
      .then(function (response) {
        setCurrentStatus("Completed...");
        success = true;
      })
      .catch(function (error) {
        alert(error);
        setCurrentStatus("");
        setErrStatus("Unable to upload csv file....");
      })
      .finally(() => {
        generateLog(success,username);
      });
  };
  const uploadFile = async (file,accessToken,username,token_stag) => {
    setCurrentStatus("Creating util file....");
    let data = {
      updateStack: [category.value],
      update: {
        sheet: category.label,
        from: fromIndex > 1 ? fromIndex - 2 : 0,
        to: toIndex > 1 ? toIndex - 2 : 5000,
        selectedIndex: selectedIndex,
        SKUBlacklist: [],
        selectedColumn: master?.selectedColumn || "",
      },
      config: {
        token: accessToken,
        base_url: appConstants.API_BASE_URL_PROD,
        base_url_mini: appConstants.API_BASE_MINI_PROD,
        token_stag: token_stag,
        user: username+"_"+userName,
        type: "PROD",
      },
    };
    var str = JSON.stringify(data);

    const blob = new Blob([str], {
      type: "application/json",
    });

    setCurrentStatus("Uploading util...");

    uploadUtil(blob)
      .then(function (response) {
        setCurrentStatus("Util uploaded...");
        let blobFile = new Blob([file], {
          type: "application/json",
        });
        postToS3(blobFile,username);
      })
      .catch(function (error) {
        setCurrentStatus("");
        setErrStatus("Unable to upload util file")
      });
  };
  const uploadUtil = (blob) => {
    var data = new FormData();
    data.append("data", blob);

    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=catalogue_utils&file_name=${category.util}_util`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: data,
    };

    return axios(config);
  };
  const createUtil = (accessToken,username,token_stag) => {
    setCurrentStatus("Fetching file..")
    downloadFile()
      .then(function (response) {
        uploadFile(response.data,accessToken,username,token_stag)
      })
      .catch(function (error) {
        setCurrentStatus("");
        setErrStatus("Something went wrong... Unable to fetch util file.")
      });
  }
  const LoginProd = () => {
    let creds = {
      username: name,
      password: password,
      type: "login_prod",
    };
    return doLogin(creds);
  }
  const moveToProd = () => {
    setErrStatus("")
    let token_stag = store.getState()?.usersReducer?.authToken;
    if (name && name != "" && password && password != "") {
      downloadFile();
      setCurrentStatus("loading...")
      LoginProd()
        .then(res => {
          createUtil(res.data?.access_token,name,token_stag);
        }).catch(err => {
          setCurrentStatus("");
          setErrStatus("Username or password incorrect")
        })
    } else {
      setCurrentStatus("")
      setErrStatus("Please enter your username and password")
    }
  }
  const setMasterData = (e) => {
    let data = props.masterData[e.value];
    setMaster(data);
  }
  const updateConfigByMaster = () => {
    if (master.from == 0)
      setFrom("")
    else
      setFrom(master.from+2)
    if (master.to == 5000)
      setTo("")
    else
      setTo(master.to+2)
    setSelected(master.selectedIndex)
  }
  return (
    <div style={{ borderBottom: "1px solid #5555" }}>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        pr={1}
      >
        <MDBox lineHeight={1.125}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {props.label}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular" color="text">
            {new Date(props.date).toLocaleDateString()} {" @ "}
            {new Date(props.date).toLocaleTimeString("en-US")}
            {" (Size : " + props.size + " Kb)"}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            lineHeight={1}
            ml={3}
            onClick={() => setShowToast(!showToast)}
            sx={{
              cursor: "pointer",
              border: "1px solid green",
              borderRadius: 5,
              padding: "0 5px",
            }}
          >
            <Icon fontSize="small">{showToast ? "remove" : "check"}</Icon>
            <MDTypography
              variant="button"
              fontWeight="bold"
              style={{ marginLeft: 5 }}
            >
              {!showToast ? "Move to production" : "Close"}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      {showToast ? (
        <div>
          {props.allowParams && (master?.form || master?.to) ? (
            <MDBox
              style={{ backgroundColor: "#aaa2", cursor: "pointer" }}
              onClick={updateConfigByMaster}
              p={1}
              m={1}
            >
              <MDTypography
                display="block"
                variant="caption"
                fontWeight="medium"
                color="black"
              >
                {`Tap to use previous template to update from column ${
                  master.from + 2
                } to ${
                  master.to != 5000 ? "column " + (master.to + 2) : "EOF"
                }${
                  master.selectedIndex != []
                    ? " with selected index: " + master.selectedIndex
                    : ""
                } ${master.updatedBy ? "updated by " + master.updatedBy : ""}`}
              </MDTypography>
            </MDBox>
          ) : null}
          <MDBox component="li" display="flex" alignItems="center" px={1}>
            {props.allowParams ?
              <div>
                <MDInput
                  type="number"
                  value={fromIndex}
                  onChange={(e) => setFrom(e.target.value)}
                  style={{ marginRight: 10 }}
                  placeholder="Load from row"
                />
                <MDInput
                  type="number"
                  value={toIndex}
                  onChange={(e) => setTo(e.target.value)}
                  style={{ marginRight: 10 }}
                  placeholder="Load till row"
                />
                <MDInput
                  value={selectedIndex}
                  style={{ marginRight: 10 }}
                  onChange={(e) => setSelected(e.target.value)}
                  placeholder="Load selected row"
                />
              </div>:null}
            <Select
              key={category.value}
              value={category}
              onChange={(e) => {
                setCategory(e);
                setMasterData(e);
              }}
              options={props.type.category}
            />
          </MDBox>
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            px={1}
          >
            <MDBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ marginRight: 10 }}
                placeholder="Enter username"
              />
              <MDInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </MDBox>
            <MDButton
              disabled={currentStatus != "" || name == "" || password == ""}
              onClick={moveToProd}
              type="button"
              color="info"
            >
              {currentStatus != "" ? currentStatus : "Move to production"}
            </MDButton>
          </MDBox>
          {errStatus != "" && (
            <MDTypography
              display="block"
              variant="caption"
              fontWeight="medium"
              color="error"
              style={{ padding: 10 }}
            >
              {errStatus}
            </MDTypography>
          )}
        </div>
      ) : null}
    </div>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
