import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import Dropzone from "../../components/DropZone";
import axios from "axios";

import { store } from "store";
import { appConstants } from "constants/AppConstants";
import NotificationItem from "examples/Items/NotificationItem";
import readXlsxFile from "read-excel-file";

var FormData = require("form-data");
let dropKey = 0;

const Upload = (props) => {
  const [dropSelected, setDropSelected] = useState(props.options[0]);
  const [fromIndex, setFromIndex] = useState();
  const [toIndex, setToIndex] = useState();
  const [selectedIndex, setSelectedIndex] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");

  const userName =
    store.getState().usersReducer?.userData?.username +
    "_" +
    store.getState().usersReducer?.userData?.full_name?.first_name +
    " " +
    store.getState().usersReducer?.userData?.full_name?.last_name;

  const [videoRes, setVideoRes] = useState({
    success: [],
    failure: [],
    uploading: [],
  });

  React.useEffect(() => {
    dropKey = dropKey + 1;
    setCurrentStatus("");
    setDropSelected(props.options[0]);
  }, [props.type]);

  const generateLog = async (success) => {
    let data = {
      updateStack: [dropSelected.value],
      update: {
        sheet: dropSelected.label,
        from: fromIndex > 1 ? fromIndex - 2 : 0,
        to: toIndex > 1 ? toIndex - 2 : 5000,
        selectedIndex: selectedIndex,
      },
      config: {
        base_url: appConstants.API_BASE_URL,
        base_url_mini: appConstants.API_BASE_MINI,
      },
      updateDetails: {
        user: userName,
        uploadTime: new Date(),
        uploadSucceeded: success ? "Yes" : "No",
        csvFileName: dropSelected.value,
        fileType: props.type,
        uiltFile: `${dropSelected.util}_util`,
      },
    };
    var str = JSON.stringify(data);

    const blob = new Blob([str], {
      type: "application/json",
    });

    var dataConf = new FormData();
    dataConf.append("data", blob);
    let d = new Date();
    let dateFormatted =
      d.getFullYear() +
      "-" +
      (d.getMonth() > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)) +
      "-" +
      (d.getDate() > 9 ? d.getDate() : "0" + d.getDate());
    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=admin_log&file_name=${dateFormatted}/frontend/staging/${
        props.type
      }_${Date.now()}`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: dataConf,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const generateLogVideo = async (videoRes) => {
    let data = {
      updateStack: [dropSelected.value],
      config: {
        base_url: appConstants.API_BASE_URL,
        base_url_mini: appConstants.API_BASE_MINI,
      },
      updateDetails: {
        user: userName,
        uploadTime: new Date(),
        videos: videoRes,
        fileType: props.type,
      },
    };
    var str = JSON.stringify(data);

    const blob = new Blob([str], {
      type: "application/json",
    });

    var dataConf = new FormData();
    dataConf.append("data", blob);
    let d = new Date();
    let dateFormatted =
      d.getFullYear() +
      "-" +
      (d.getMonth() > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)) +
      "-" +
      (d.getDate() > 9 ? d.getDate() : "0" + d.getDate());
    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=admin_log&file_name=${dateFormatted}/frontend/staging/${
        props.type
      }_${Date.now()}`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: dataConf,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const postToS3 = (file) => {
    setCurrentStatus("Fetching csv file....");
    var data = new FormData();
    data.append("data", file);

    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=${props.type}&file_name=${dropSelected.value}`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: data,
    };
    setCurrentStatus("Uploading csv file....");
    let success = false;
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setCurrentStatus("Successfully updated csv file....");
        success = true;
      })
      .catch(function (error) {
        console.log(error);
        setCurrentStatus("Unable to upload csv file....");
      })
      .finally(() => {
        generateLog(success);
      });
  };
  const downloadFile = () => {
    var config = {
      method: "get",
      url: `https://super-lambda.flyotech.com/super-lambda-api-downloadS3FilesLambdaFunction-mAsosFEqMWTe?type=catalogue_utils&file_name=master_util.json`,
      headers: {
        api_key: "1234!@#$%&",
      },
    };
    return axios(config);
  };
  const getSignedVideoUrl = (name) => {
    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-getSignedUrlLambdaFunction-xdigPsE8Acac?type=short_video_upload&file_name=${name}`,
      headers: {
        api_key: "1234!@#$%&",
      },
    };
    return axios(config);
  };
  const getSignedUrl = (name) => {
    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-getSignedUrlLambdaFunction-xdigPsE8Acac?type=sorting_product_output&file_name=${name}`,
      headers: {
        api_key: "1234!@#$%&",
      },
    };
    return axios(config);
  };
  const postToS3Video = (url, file) => {
    var config = {
      method: "PUT",
      url: url,
      headers: {
        "content-type": "video/mp4",
      },
      data: file,
    };
    return axios(config);
  };
  const postToS3CSV = (url, file) => {
    setCurrentStatus("Fetching csv file....");
    var data = new FormData();
    data.append("data", file);
    var config = {
      method: "PUT",
      url: url,
      headers: {
        "content-type": "text/csv",
      },
      data: file,
    };
    return axios(config);
  };
  const createAssetMetaData = (data) => {
    let token = store.getState().usersReducer.authToken;
    let fileUrl = `https://content-network.flyotech.com/videos/${
      data?.split(".")[0]
    }/${data?.split(".")[0]}.m3u8`;
    var config = {
      method: "POST",
      url: `https://super-lambda.flyotech.com/super-lambda-api-apiWrapperLambdaFunction-NKQl56ToZTwm?type=asset_metadata&token=${token}`,
      data: {
        name: data,
        link: fileUrl,
        type: "video",
        dimension: dropSelected?.value,
      },
      headers: {
        api_key: "1234!@#$%&",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  };
  const updateUtilSheet = async (data) => {
    let masterData = {};
    downloadFile()
      .then((res) => {
        masterData = res.data;
        setTimeout(() => {
          masterData[dropSelected.value] = {
            ...data,
            updatedBy: userName,
            updatedOn: new Date(),
          };
          var str = JSON.stringify(masterData);
          const blob = new Blob([str], {
            type: "application/json",
          });
          uploadUtil(blob, "master");
        }, 10);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportToCsv = (rows) => {
    var processRow = (row) => {
      var finalVal = "";
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ",";
        finalVal += result;
      }
      return finalVal + "\n";
    };

    var csvFile = "";
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    return blob;
  };
  const postSheet = (sheet, file) => {
    setCurrentStatus("Fetching csv file....", sheet);
    var data = new FormData();
    data.append("data", file);

    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=${sheet}&file_name=${sheet}`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: data,
    };
    setCurrentStatus("Uploading csv file....");
    axios(config)
      .then(() => {
        setCurrentStatus("Successfully updated csv file....");
      })
      .catch((error) => {
        setCurrentStatus("Unable to upload csv file....");
      });
  };
  const handleXls = async (file) => {
    let sheets = ["field_weights", "brand_priority_list", "category_base_data"];
    let sheetNames = [
      "Field Weights",
      "Brand Priority List",
      "Category Base Data",
    ];

    await sheetNames.forEach(async (sheetName, index) => {
      let res = await readXlsxFile(file, { sheet: sheetName });
      let new_file = exportToCsv(res);
      postSheet(sheets[index], new_file);
    });

    let res = await readXlsxFile(file, { sheet: "Master" });
    let new_file = exportToCsv(res);
    setTimeout(() => {
      getSignedUrl("master.csv")
        .then((res) => {
          console.log("321", res);
          setCurrentStatus("Creating util file....");
          let token_stag = store.getState()?.usersReducer?.authToken;
          let data = {
            updateStack: [dropSelected.value],
            update: {
              sheet: dropSelected.label,
              from: fromIndex > 1 ? fromIndex - 2 : 0,
              to: toIndex > 1 ? toIndex - 2 : 5000,
              selectedIndex: selectedIndex,
              SKUBlacklist: [],
              selectedColumn: selectedColumn,
            },
            config: {
              token: token_stag,
              base_url: appConstants.API_BASE_URL,
              base_url_mini: appConstants.API_BASE_MINI,
              token_stag: token_stag,
              user: userName,
              type: "STAG",
            },
          };
          var str = JSON.stringify(data);
      
          const blob = new Blob([str], {
            type: "application/json",
          });
          setCurrentStatus("Uploading util file....");
          uploadUtil(blob, dropSelected.util)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
              setCurrentStatus("Successfully updated util file....");
              postToS3CSV(res?.data, new_file)
              setTimeout(() => {
                updateUtilSheet(data.update);
              }, 10);
            })
            .catch(function (error) {
              console.log(error);
              setCurrentStatus("Unable to upload util file....");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
  };

  const uploadFileData = async (file) => {
    setCurrentStatus("Creating util file....");
    let token_stag = store.getState()?.usersReducer?.authToken;
    let data = {
      updateStack: [dropSelected.value],
      update: {
        sheet: dropSelected.label,
        from: fromIndex > 1 ? fromIndex - 2 : 0,
        to: toIndex > 1 ? toIndex - 2 : 5000,
        selectedIndex: selectedIndex,
        SKUBlacklist: [],
        selectedColumn: selectedColumn,
      },
      config: {
        token: token_stag,
        base_url: appConstants.API_BASE_URL,
        base_url_mini: appConstants.API_BASE_MINI,
        token_stag: token_stag,
        user: userName,
        type: "STAG",
      },
    };
    var str = JSON.stringify(data);

    const blob = new Blob([str], {
      type: "application/json",
    });
    setCurrentStatus("Uploading util file....");
    if (props.isVideoUpload) {
      let specialChars = /[`!@#$%^&*()+\s\-=\[\]{};':"\\|,<>\/?~]/;
      if (specialChars.test(file?.name)) {
        let newFail = videoRes?.failure;
        newFail.push(file.name);
        setVideoRes({ ...videoRes, failure: newFail });
        setCurrentStatus("Wrong file name = " + file.name);
      } else if (file?.size > 50000000) {
        let newFail = videoRes?.failure;
        newFail.push(file.name);
        setVideoRes({ ...videoRes, failure: newFail });
        setCurrentStatus(`Video exceeded size limit = ${file.name} with size = ${parseInt(file.size/1000000)}MB, max size allowed is 50MB`);
      } else if (file.name.includes(".mp4")) {
        getSignedVideoUrl(file.name)
          .then((res) => {
            let newUpl = videoRes?.uploading;
            let newFail = videoRes?.failure?.filter((e) => e != file.name);
            newUpl.push(file.name);

            setVideoRes({ ...videoRes, uploading: newUpl, failure: newFail });

            postToS3Video(res?.data, file)
              .then((res) => {
                createAssetMetaData(file?.name)
                  .then(() => {
                    let newSuccess = videoRes?.success;
                    newSuccess.push(file.name);
                    setVideoRes({ ...videoRes, success: newSuccess });
                    if (
                      videoRes?.uploading?.length <=
                      videoRes?.success?.length + videoRes?.failure?.length
                    ) {
                      generateLogVideo({
                        videoRes: videoRes,
                        file: file?.name,
                      });
                    }
                  })
                  .catch((e) => {
                    console.log("error-> " + e + " on " + file.name);
                    let newFail = videoRes?.failure;
                    newFail.push(file.name);
                    setVideoRes({ ...videoRes, failure: newFail });
                  });
              })
              .catch((e) => {
                let newFail = videoRes?.failure;
                console.log("error->" + newFail);
                newFail.push(file.name);
                console.log("error->" + newFail);
                setVideoRes({ ...videoRes, failure: newFail });
              });
          })
          .catch((e) => {
            let newFail = videoRes?.failure;
            console.log("error->" + newFail);
            newFail.push(file.name);
            console.log("error->" + newFail);
            setVideoRes({ ...videoRes, failure: newFail });
          })
          .finally(() => {
            if (videoRes?.failure?.length) {
              setCurrentStatus(
                "Encountered some issues while uploading videos = " +
                  videoRes?.failure
              );
            } else {
              setCurrentStatus("Successfully started uploading videos, please wait for the upload icon to turn green");
            }
          });
      } else {
        let newFail = videoRes?.failure;
        newFail.push(file.name);
        setVideoRes({ ...videoRes, failure: newFail });
        setCurrentStatus("Wrong file type = " + file.name);
      }
    } else {
      uploadUtil(blob, dropSelected.util)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setCurrentStatus("Successfully updated util file....");
          postToS3(file);
          setTimeout(() => {
            updateUtilSheet(data.update);
          }, 10);
        })
        .catch(function (error) {
          console.log(error);
          setCurrentStatus("Unable to upload util file....");
        });
    }
  };

  const uploadFile = async (file) => {
    if (dropSelected.value === "sorting_product_output") {
      handleXls(file);
    } else {
      uploadFileData(file);
    }
  };

  const uploadUtil = (blob, utilFileName) => {
    var data = new FormData();
    data.append("data", blob);

    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-catalogueFileUploadLambdaFunction-h27yue7zoW6r?type=catalogue_utils&file_name=${utilFileName}_util`,
      headers: {
        api_key: "1234!@#$%&",
        "content-type": "multipart/form-data",
      },
      data: data,
    };

    return axios(config);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox px={5} py={3}>
        <Grid container spacing={3}>
          <Grid item>
            <Dropzone
              dropKey={dropKey}
              options={props.options}
              onSelect={(e) => setDropSelected(e)}
              selected={dropSelected}
              onUpload={uploadFile}
              fromIndex={fromIndex}
              isVideoUpload={props.isVideoUpload}
              accept={props?.accept || null}
              setFromIndex={(e) => {
                setFromIndex(e.target.value);
              }}
              toIndex={toIndex}
              setToIndex={(e) => {
                setToIndex(e.target.value);
              }}
              selectedIndex={selectedIndex}
              setSelectedIndex={(e) => {
                setSelectedIndex(e.target.value);
              }}
              allowParams={props.allowParams}
              videoRes={videoRes}
              hasColumnInputs={props.hasColumnInputs}
              selectedColumn={selectedColumn}
              setSelectedColumn={(e) => setSelectedColumn(e.target.value)}
            />
          </Grid>
        </Grid>
      </MDBox>
      <NotificationItem
        icon={
          <Icon fontSize="small">{currentStatus != "" ? "upload" : null}</Icon>
        }
        title={currentStatus}
      />
    </DashboardLayout>
  );
};

export default Upload;
