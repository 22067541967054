import { RemoveCircle } from "@mui/icons-material";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";

const specs = {
  Collage2: {
    isCollage: true,
    numberOfImages: 2,
    specs: [
      { h: "1", w: "0.5" },
      { h: "1", w: "0.5" },
    ],
    indent: "l",
  },
  Collage3: {
    isCollage: true,
    numberOfImages: 3,
    specs: [
      { h: "1", w: "0.5" },
      { h: "0.5", w: "0.5" },
      { h: "0.5", w: "0.5" },
    ],
    indent: "l",
  },
  Collage4: {
    isCollage: true,
    numberOfImages: 4,
    specs: [
      { h: "0.5", w: "0.5" },
      { h: "0.5", w: "0.5" },
      { h: "0.5", w: "0.5" },
      { h: "0.5", w: "0.5" },
    ],
    indent: "r",
  },
  Collage5: {
    isCollage: true,
    numberOfImages: 5,
    specs: [
      { h: "0.5", w: "0.666" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
    ],
    indent: "t",
  },
  Collage6: {
    isCollage: true,
    numberOfImages: 6,
    specs: [
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
      { h: "0.5", w: "0.333" },
    ],
    indent: "l",
  },
};

const LayoutManager = () => {
  const [elemType, setElemType] = useState("Collage2");
  const [updated, setUpdated] = useState({});
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1080);
  const [images, setImages] = useState(["", "", "", "", "", ""]);
  const [keyVal, setKeyVal] = useState(0);
  const [isMsite, setIsMsite] = useState(false);
  const [sections, setSections] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubSection, setSelectedSubSection] = useState("");

  useEffect(() => {
    if (specs?.[elemType]) {
      setUpdated(specs?.[elemType]);
      setKeyVal(keyVal + 1);
    }
  }, [elemType]);

  const updateImage = (index, image) => {
    const temp = [...images];
    temp[index] = image;
    setKeyVal(keyVal + 1);
    setImages(temp);
  };

  useEffect(() => {
    setWidth(isMsite ? 360 : 1920);
    setHeight(isMsite ? 640 : 1080);
  }, [isMsite]);

  const Card = ({ index }) => {
    return (
      <Grid
        item
        key={index}
        style={{
          backgroundColor: "#0002",
          border: "1px solid black",
          height: updated?.specs?.[index]?.h * 500,
          width: (updated?.specs?.[index]?.w * 500 * width) / height,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: 14,
            backgroundColor: "#000a",
            color: "#fff",
            position: "absolute",
            top: 4,
            left: 4,
            padding: 4,
          }}
        >
          {parseInt(updated?.specs?.[index]?.w * width)} X {parseInt(updated?.specs?.[index]?.h * height)}
        </div>
        {images?.[index] ? (
          <img
            src={images?.[index]}
            alt="img"
            style={{ width: "100%", height: "100%", overflow: "hidden", objectFit: "cover" }}
          />
        ) : null}
      </Grid>
    );
  };

  const copyData = () => {
    navigator.clipboard.writeText(
      JSON.stringify({
        specs: updated?.specs,
        height,
        width,
        padding: 0,
        margin: 0,
        scrollable: false,
        indent: updated?.indent,
      })
    );
  };

  const addSection = () => {
    let prompt = window.prompt("Enter Section Name");
    if (prompt) {
      setSections({ ...sections, [prompt]: {} });
      setSelectedSection(prompt);
    }
  };

  const addSubSection = () => {
    let prompt = window.prompt("Enter Sub Section Name");
    if (prompt) {
      setSections({ ...sections, [selectedSection]: { ...sections?.[selectedSection], [prompt]: [] } });
      setSelectedSubSection(prompt);
    }
  };

  const removeSection = (section) => {
    let temp = { ...sections };
    delete temp[section];
    setSections(temp);
  };

  const removeSubSection = (subSection) => {
    let temp = { ...sections };
    delete temp[selectedSection][subSection];
    setSections(temp);
  };

  const addSku = (p) => {
    // if sku already exists remove sku
    let sku = p;
    console.log(p);
    if (!p || p == "") {
      sku = window.prompt("Enter SKU");
      if (!sku) {
        return;
      }
    }
    if (sections?.[selectedSection]?.[selectedSubSection]?.includes(sku)) {
      setSections({
        ...sections,
        [selectedSection]: {
          ...sections?.[selectedSection],
          [selectedSubSection]: sections?.[selectedSection]?.[selectedSubSection]?.filter((i) => i !== sku),
        },
      });
    } else {
      setSections({
        ...sections,
        [selectedSection]: {
          ...sections?.[selectedSection],
          [selectedSubSection]: [...sections?.[selectedSection]?.[selectedSubSection], sku],
        },
      });
    }
  };

  const copySection = () => {
    navigator.clipboard.writeText(JSON.stringify(sections));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox px={5} py={3}>
        <Grid container spacing={3}>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <MDInput value={width} onChange={(e) => setWidth(e?.target?.value)} label="WIDTH" />
              </Grid>
              <Grid item>
                <MDInput value={height} onChange={(e) => setHeight(e?.target?.value)} label="HEIGHT" />
              </Grid>
              <Grid item>
                <MDInput value={elemType} onChange={(e) => setElemType(e?.target?.value)} label="Element Type" />
              </Grid>
              <Grid item>
                <MDButton onClick={() => setIsMsite(!isMsite)} color={"success"} size={"medium"}>
                  {isMsite ? "Msite" : "Dsite"}
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton onClick={copyData} color={"success"} size={"medium"}>
                  COPY
                </MDButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 16 }}>
              {Array.from({ length: updated?.numberOfImages }, () => {
                return 1;
              })?.map((_, index) => (
                <Grid item key={index}>
                  <MDInput
                    value={images?.[index]}
                    onChange={(e) => updateImage(index, e?.target?.value)}
                    label={`Image ${index + 1}`}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 24 }}>
          <Grid item>
            {updated?.numberOfImages == 3 ? (
              <Grid
                container
                style={{
                  width: (500 * width) / height,
                  height: 500,
                  backgroundColor: "pink",
                  flexDirection: updated?.indent === "l" ? "row" : "row-reverse",
                }}
              >
                <Grid item>
                  <Card index={0} />
                </Grid>
                <Grid item>
                  <Card index={1} />
                  <Card index={2} />
                </Grid>
              </Grid>
            ) : updated?.numberOfImages == 5 ? (
              <Grid
                container
                style={{
                  width: (500 * width) / height,
                  height: 500,
                  backgroundColor: "pink",
                  display: "flex",
                  flexDirection: updated?.indent === "t" ? "column" : "column-reverse",
                }}
              >
                <Grid item style={{ flexDirection: "row", display: "flex" }}>
                  <Card index={0} />
                  <Card index={1} />
                </Grid>
                <Grid item style={{ flexDirection: "row", display: "flex" }}>
                  <Card index={2} />
                  <Card index={3} />
                  <Card index={4} />
                </Grid>
              </Grid>
            ) : updated?.numberOfImages ? (
              <Grid
                container
                style={{
                  width: (500 * width) / height,
                  height: 500,
                  backgroundColor: "pink",
                  overflow: "hidden",
                  flexDirection: updated?.indent === "l" ? "row" : "row-reverse",
                }}
              >
                {Array.from({ length: updated?.numberOfImages }, () => {
                  return 1;
                })?.map((_, index) => (
                  <Card index={index} key={index} />
                ))}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 8 }}>
          <JSONInput
            height={"400px"}
            width={"700px"}
            theme="dark_vscode_tribute"
            placeholder={updated}
            onBlur={(e) => setUpdated(e.jsObject)}
            key={keyVal}
          />
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 24 }}>
          <Grid item>
            <h2>Multi Section</h2>
            <Grid container spacing={3} style={{ marginTop: 24 }}>
              {sections
                ? Object.keys(sections)?.map((i) => {
                    return (
                      <Grid item key={i}>
                        <MDButton
                          onClick={() => setSelectedSection(i)}
                          color={selectedSection == i ? "primary" : "secondary"}
                          size={"medium"}
                        >
                          {i}
                        </MDButton>
                        {selectedSection == i ? (
                          <RemoveCircle
                            style={{
                              cursor: "pointer",
                              color: "red",
                              marginLeft: 8,
                              fontSize: 16,
                            }}
                            onClick={() => removeSection(i)}
                          />
                        ) : null}
                      </Grid>
                    );
                  })
                : null}
              <Grid item>
                <MDButton onClick={addSection} color={"info"} size={"medium"}>
                  + Section
                </MDButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 24 }}>
              {sections?.[selectedSection]
                ? Object.keys(sections?.[selectedSection])?.map((i) => {
                    return (
                      <Grid item key={i}>
                        <MDButton
                          onClick={() => setSelectedSubSection(i)}
                          color={selectedSubSection == i ? "primary" : "secondary"}
                          size={"medium"}
                        >
                          {i}
                        </MDButton>
                        {selectedSubSection == i ? (
                          <RemoveCircle
                            style={{
                              cursor: "pointer",
                              color: "red",
                              marginLeft: 8,
                              fontSize: 16,
                            }}
                            onClick={() => removeSubSection(i)}
                          />
                        ) : null}
                      </Grid>
                    );
                  })
                : null}
              <Grid item>
                <MDButton onClick={addSubSection} color={"info"} size={"medium"}>
                  + Sub Section
                </MDButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 24 }}>
              {sections?.[selectedSection]?.[selectedSubSection]?.length
                ? sections?.[selectedSection]?.[selectedSubSection]?.map((i) => {
                    return (
                      <Grid item key={i}>
                        <MDButton onClick={() => addSku(i)} color={"dark"} size={"small"}>
                          {i}
                        </MDButton>
                      </Grid>
                    );
                  })
                : null}
              {sections?.[selectedSection]?.[selectedSubSection] ? (
                <Grid item>
                  <MDButton onClick={() => addSku("")} color={"info"} size={"small"}>
                    + ADD SKU
                  </MDButton>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item>
            <MDButton onClick={copySection} color={"success"} size={"medium"}>
              Copy
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default LayoutManager;
