import { actionTypes } from "../../constants/ActionTypes";

const initialState = {
  changeLog: {},
  selected: [],
  selectedKey: "",
};

const changelogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CHANGELOG_DATA:
      let newData = {
        name: action.payload.name,
        data: action.payload.data,
        time: new Date()
      }
      let data = state.changeLog;
      data[action.payload.name] = newData;
      return {
        ...state,
        changeLog: data,
      };
    case actionTypes.SET_CHANGELOG_DATA:
      return {
        ...state,
        selected: action.payload.data,
        selectedKey: action.payload.key,
      };
    case actionTypes.UPDATE_CHANGELOG_DATA:
      let change = state.changeLog;
      let update = change[action.payload.toUpdate];
      console.log(action.payload)
      update.data = action.payload.data;
      update.updatedAt = new Date();
      change[action.payload.toUpdate] = update;
      return {
        ...state,
        changeLog: change,
        selected: action.payload.data,
      };
    case actionTypes.REMOVE_CHANGELOG_DATA:
      let remove = state.changeLog;
      delete remove[action.payload];
      return {
        ...state,
        changeLog: remove,
      };
    default:
      return state;
  }
};

export default changelogReducer;
