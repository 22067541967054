import Select from "react-select";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Invoices from "layouts/billing/components/Invoices";
import React, { useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";

const typeList = [
  {
    label: "Brand & Category",
    value: "brand_and_category",
    allowParams: true,
    category: [
      {
        label: "Brand",
        value: "Brand",
        util: "Brand_Category",
        type: "brand_and_category",
      },
      {
        label: "Category",
        value: "Category",
        util: "Brand_Category",
        type: "brand_and_category",
      },
    ],
  },
  {
    label: "Catelog",
    value: "product",
    allowParams: true,
    category: [
      {
        label: "Product",
        value: "Product",
        util: "product",
        type: "product",
      },
    ],
  },
  {
    label: "Sorting & Discount",
    value: "sorting_and_pricing",
    allowParams: true,
    category: [
      {
        label: "Sorting",
        value: "sorting",
        util: "sorting_and_pricing",
        type: "sorting_and_pricing",
      },
      {
        label: "Pricing",
        value: "discount",
        util: "sorting_and_pricing",
        type: "sorting_and_pricing",
      },
    ],
  },
  {
    label: "Product variants",
    value: "product_variants",
    allowParams: true,
    category: [
      {
        label: "Product variant",
        value: "product_variants",
        util: "product_variant",
        type: "product_variants",
      },
    ],
  },
  {
    label: "Usecase",
    value: "usecase",
    allowParams: true,
    category: [
      {
        label: "Usecase",
        value: "usecase",
        util: "usecase",
        type: "usecases",
      },
    ],
  },
  {
    label: "Brand Collection",
    value: "brand_collection",
    allowParams: true,
    category: [
      {
        label: "Brand Collection",
        value: "brand_collection",
        util: "brand_collection",
        type: "brand_collection",
      },
    ],
  },
  {
    label: "Product Collection",
    value: "product_collections",
    allowParams: true,
    category: [
      {
        label: "Product Collection",
        value: "product_collections",
        util: "product_collections",
        type: "product_collections",
      },
    ],
  },
  {
    label: "Feed",
    value: "feed",
    allowParams: true,
    category: [
      {
        label: "Feed",
        value: "feed_content",
        util: "feed_content",
        type: "feed_content",
      },
    ],
  },
  {
    label: "Static Feed",
    value: "static_feed",
    allowParams: true,
    category: [
      {
        label: "Static Feed",
        value: "static_feed_content",
        util: "static_feed_content",
        type: "static_feed_content",
      },
    ],
  },
  {
    label: "Homepage Component",
    value: "homepage_component",
    allowParams: true,
    category: [
      {
        label: "Homepage Component",
        value: "homepage_component",
        util: "homepage_component",
        type: "homepage_component",
      },
    ],
  },
  {
    label: "Homepage Component Element",
    value: "homepage_component_element",
    allowParams: true,
    category: [
      {
        label: "Homepage Component Element",
        value: "homepage_component_element",
        util: "homepage_elements",
        type: "homepage_component_element",
      },
    ],
  },
  {
    label: "Coupon",
    value: "coupon",
    allowParams: true,
    category: [
      {
        label: "Coupon",
        value: "coupon",
        util: "coupons",
        type: "coupon",
      },
    ],
  },
  {
    label: "Postal Code",
    value: "postal_code",
    allowParams: true,
    category: [
      {
        label: "Postal Code",
        value: "postal_code",
        util: "postal_code",
        type: "postal_code",
      },
    ],
  },
  {
    label: "Quick Filters",
    value: "quick_filters",
    allowParams: true,
    category: [
      {
        label: "Quick Filters",
        value: "quick_filters",
        util: "quick_filters",
        type: "quick_filters",
      },
    ],
  },
  {
    label: "Stories",
    value: "stories",
    allowParams: true,
    category: [
      {
        label: "Stories",
        value: "stories",
        util: "stories",
        type: "stories",
      },
    ],
  },
  {
    label: "Multi Stories",
    value: "multi_stories",
    allowParams: true,
    category: [
      {
        label: "Multi Stories",
        value: "multi_stories",
        util: "multi_stories",
        type: "multi_stories",
      },
    ],
  },
  // {
  //   label: "Whatsapp Gupshup",
  //   value: "gupshup_whatsapp_numbers",
  //   allowParams: true,
  //   category: [
  //     {
  //       label: "Whatsapp Gupshup",
  //       value: "gupshup_whatsapp_numbers",
  //       util: "gupshup_whatsapp",
  //       type: "gupshup_whatsapp_numbers",
  //     },
  //   ],
  // },
  {
    label: "Product Reviews",
    value: "product_review",
    allowParams: true,
    category: [
      {
        label: "Product Reviews",
        value: "product_review",
        util: "product_review",
        type: "product_review",
      },
    ],
  },
  {
    label: "Rate Breakup",
    value: "rate_breakup",
    allowParams: true,
    category: [
      {
        label: "Rate Breakup",
        value: "rate_breakup",
        util: "rate_breakup",
        type: "rate_breakup",
      },
    ],
  },
  {
    label: "Sorting Product",
    value: "sorting_product_output",
    allowParams: false,
    category: [
      {
        label: "Sorting Product",
        value: "sorting_product_output",
        util: "sorting_product_output",
        type: "sorting_product_output",
      },
    ],
  },
  {
    label: "Quick Banners",
    value: "quick_banners",
    allowParams: true,
    category: [
      {
        label: "Quick Banners",
        value: "quick_banners",
        util: "quick_banners",
        type: "quick_banners",
      },
    ],
  },
  {
    label: "Inventory",
    value: "inventory",
    allowParams: false,
    category: [
      {
        label: "Inventory",
        value: "inventory",
        util: "inventory",
        type: "inventory",
      },
    ],
  },
  {
    label: "Blogs",
    value: "blog_page",
    allowParams: true,
    category: [
      {
        label: "Blogs",
        value: "blog_page",
        util: "blog_page",
        type: "blog_page",
      },
    ],
  },
  {
    label: "QnA",
    value: "product_qnas",
    allowParams: true,
    category: [
      {
        label: "QnA",
        value: "product_qnas",
        util: "product_qnas",
        type: "product_qnas",
      },
    ],
  },
];
export default function index() {
  const [type, setType] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keySelect, setKeySelect] = useState(0);
  const [masterData, setMasterData] = useState({});

  const fetchMasterData = async () => {
    var config = {
      method: "get",
      url: `https://super-lambda.flyotech.com/super-lambda-api-downloadS3FilesLambdaFunction-mAsosFEqMWTe?type=catalogue_utils&file_name=master_util.json`,
      headers: {
        api_key: "1234!@#$%&",
      },
    };
    axios(config).then((res) => {
      setMasterData(res.data);
    });
  };
  const listFiles = async (e) => {
    await setKeySelect(keySelect + 1);
    await setType(e);
    setLoading(true);
    fetchMasterData();
    setTimeout(() => {
      var config = {
        method: "get",
        url: `https://super-lambda.flyotech.com/super-lambda-api-listS3FilesLambdaFunction-UySPzTQd8vMg?type=${e.value}`,
        headers: {
          api_key: "1234!@#$%&",
        },
      };

      axios(config)
        .then(function (response) {
          let data = response.data.sort(function (a, b) {
            return new Date(b.LastModified) - new Date(a.LastModified);
          });
          setFiles(data);
        })
        .catch(function (error) {
          setFiles([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 100);
  };
  return loading ? (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <h1 style={{ position: "absolute", top: "20%" }}>FLYO</h1>
      <Loader
        style={{ position: "absolute", top: "40%" }}
        type="BallTriangle"
        color="#000"
        height={150}
        width={150}
      />
    </div>
  ) : (
    <DashboardLayout>
      <DashboardNavbar isProd={true} />
      <MDBox px={5} py={3}>
        <div style={{ padding: 5, margin: 20 }}>
          <Select
            key={keySelect}
            value={type}
            onChange={(e) => {
              listFiles(e);
            }}
            options={typeList}
          />
        </div>
        {type ? (
          <Invoices
            type={type}
            loading={loading}
            files={files}
            masterData={masterData}
            allowParams={type.allowParams}
          />
        ) : (
          <h1 style={{ textAlign: "center" }}>Please select a type</h1>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
