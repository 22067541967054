import { Chip, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import JSONInput from "react-json-editor-ajrm";

const ImageMapper = () => {
  const [url, setUrl] = useState("https://assets.mabel.jewelry/images/product/MEDO_01_017_1.webp");
  const [height, setHeight] = useState(400);
  const [width, setWidth] = useState(400);
  const [selected, setSelected] = useState([]);
  const [toolTip, setToolTip] = useState({});
  const [compareKey, setCompareKey] = useState(0);

  const columns = Array.from(Array(50).keys());
  const rows = Array.from(Array(50).keys());

  const isInList = (row, column) => {
    return selected.findIndex((item) => item.row === row && item.column === column) >= 0;
  };

  const handleSelect = (row, column) => {
    const index = selected.findIndex((item) => item.row === row && item.column === column);
    if (index >= 0) {
      const newSelected = [...selected];
      newSelected.splice(index, 1);
      setSelected(newSelected);
    } else {
      let res = prompt("Enter sku code to map");
      setSelected([...selected, { row, column, sku: res }]);
    }
    setCompareKey(compareKey + 1);
  };

  const copyString = () => {
    navigator.clipboard.writeText(
      JSON.stringify({
        points: selected?.map((e) => {
          return { sku: e.sku, top: e.row * 2, left: e.column * 2 };
        }),
        width,
        height,
        url,
      })
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox px={5} py={3}>
        <Grid container spacing={3}>
          <Grid item>
            <div>
              <div
                style={{
                  marginBottom: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Chip label="url" size="small" style={{ marginRight: 8 }} />
                  <MDInput
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    style={{ marginRight: 10, width: 280 }}
                    placeholder="Enter url"
                  />
                  <Chip label="width" size="small" style={{ marginRight: 8 }} />
                  <MDInput
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    style={{ marginRight: 10, width: 80 }}
                  />
                  <Chip label="height" size="small" style={{ marginRight: 8 }} />
                  <MDInput
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    style={{ marginRight: 10, width: 80 }}
                  />
                  <MDButton onClick={copyString} style={{ marginLeft: 8, marginTop: 4 }} color={"info"}>
                    {"COPY"}
                  </MDButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "900px",
                  marginTop: 64,
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  {rows.map((row) => {
                    return (
                      <div key={row} style={{ flexDirection: "row", display: "flex", cursor: "pointer" }}>
                        {columns.map((column) => {
                          let isSelected = isInList(row, column);
                          return (
                            <div
                              key={"c" + column}
                              onMouseEnter={isSelected ? () => setToolTip({ row, column }) : null}
                              onClick={() => handleSelect(row, column)}
                              style={{
                                width: width / 50,
                                height: height / 50,
                                backgroundColor: isSelected ? "#fff" : "#0000",
                                border: "1px #aaa1 solid",
                              }}
                            >
                              {isSelected && toolTip?.row == row && toolTip.column == column ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: `${row * 2}%`,
                                    left: `${(column + 2) * 2}%`,
                                    backgroundColor: "#fff",
                                    border: "1px #000 solid",
                                    fontSize: 14,
                                    padding: "0 8px",
                                    borderRadius: 12,
                                    color: "#fff",
                                    backgroundColor: "#000",
                                  }}
                                >
                                  {selected?.map((e) =>
                                    e?.row == toolTip.row && e?.column == toolTip.column ? e?.sku : null
                                  ) || ""}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <img
                    src={url}
                    width={width}
                    height={height}
                    style={{ position: "absolute", top: 0, left: 0, zIndex: -10, objectFit: "cover" }}
                  />
                </div>
                <JSONInput
                  height={"400px"}
                  width={"400px"}
                  theme="dark_vscode_tribute"
                  placeholder={selected}
                  onBlur={(e) => setSelected(e.jsObject)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default ImageMapper;
