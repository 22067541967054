import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import ReactJsonViewCompare from "react-json-view-compare";
import DragAndDrop from "./DragAndDrop";
import MDButton from "components/MDButton";
import { store } from "store";
import axios from "axios";
import MDInput from "components/MDInput";
import { doLogin } from "layouts/authentication/API";
import MDTypography from "components/MDTypography";

const typeList = [
  {
    label: "Hardcoded meta texts",
    src: "https://cdn-icons-png.flaticon.com/512/3721/3721901.png",
    type: "text",
    inputs: ["text"],
  },
  {
    label: "Hardcoded image URLs",
    src: "https://icons.veryicon.com/png/o/miscellaneous/enterprise-common-linetype-icons/hyperlink-3.png",
    type: "text",
    inputs: ["url"],
  },
  {
    label: "Top navbar shortcut menu",
    src: "https://cdn-icons-png.flaticon.com/512/2976/2976215.png",
    type: "array",
    inputs: ["navbar", "headers"],
  },
  {
    label: "Footer Navigations links",
    src: "https://icons.veryicon.com/png/o/miscellaneous/common-channel-icon-library/navigation-bar.png",
    type: "object",
    inputs: ["footer", "navs"],
  },
  {
    label: "Footer search links",
    src: "https://cdn-icons-png.flaticon.com/512/3917/3917754.png",
    type: "array",
    inputs: ["footer", "searches"],
  },
  {
    label: "Footer USP strips images",
    src: "https://cdn-icons-png.flaticon.com/512/4827/4827307.png",
    type: "text",
    inputs: ["footer", "usp_images"],
  },
];

export const WebsiteData = () => {
  let tok = store.getState().usersReducer?.authToken;
  const [dataX, setDataX] = useState({});
  const [selected, setSelected] = useState(null);
  const [finalData, setFinalData] = useState({});
  const [compareKey, setCompareKey] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [showProd, setShowProd] = useState(false);
  const [uName, setUName] = useState("");
  const [uPassword, setUPassword] = useState("");
  const [env, setEnv] = useState("staging");

  const [statusData, setStatusData] = useState({
    text: "Loading please wait ...",
    color: "dark",
  });

  useEffect(() => {
    var config = {
      method: "get",
      url: `https://super-lambda.flyotech.com/super-lambda-api-appDataApiWrapperLambdaFunction-qs2xHLaLSOKA?type=get_app_data_${
        showProd ? "prod" : "staging"
      }&title=ui_constants`,
    };
    axios(config)
      .then(function (response) {
        setDataX(response?.data?.data?.value);
        if (showProd) {
          setCompareKey(10000);
          setEnv("production");
        } else {
          setFinalData(JSON.parse(JSON.stringify(response?.data?.data?.value)));
          setEnv("staging");
        }
      })
      .catch(function (error) {
        setDataX({
          error: "something went wrong!",
          message: error?.message,
        });
      })
      .finally(() => {
        setStatusData({ text: "Submit", color: "info" });
      });
  }, [showProd]);

  const onClick = async (item) => {
    let data = { ...finalData };
    if (typeList[selected]?.inputs?.length > 1) {
      data[typeList[selected]?.inputs[0]][typeList[selected]?.inputs[1]] = item;
    } else {
      data[typeList[selected]?.inputs[0]] = item;
    }
    setFinalData({ ...data });
    setSelected(null);
    setCompareKey(compareKey + 1);
  };

  const LoginProd = () => {
    let creds = {
      username: uName,
      password: uPassword,
      type: "login_prod",
    };
    return doLogin(creds);
  };

  const moveToProd = async () => {
    let res = prompt(
      "Are you sure you want to make changes to production? (yes/no)"
    );
    if (res != "yes") {
      return null;
    } else {
      LoginProd()
        .then((res) => {
          let token = res?.data?.access_token;
          onSubmit(token);
        })
        .catch((e) => {
          alert("Failed to login to production! Wrong credentials?");
          console.log(e);
        });
    }
  };

  const onSubmit = async (token=null) => {
    setStatusData({ text: "Submitting...", color: "warning" });
    var config = {
      method: "post",
      url: `https://super-lambda.flyotech.com/super-lambda-api-appDataApiWrapperLambdaFunction-qs2xHLaLSOKA?type=update_app_data_${
        token && token!='' ? "prod" : "staging"
      }&title=ui_constants`,
      headers: {
        Authorization: `Bearer ${token && token!='' ? token : tok}`,
      },
      data: { ...finalData, update_time: new Date().toISOString() },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("Submitted successfully to " + env + "!");
        setShowProd(true);
        setStatusData({ text: "Submitted", color: "success" });
      })
      .catch(function (error) {
        console.log(error);
        setShowProd(false);
        setStatusData({ text: "Failed to submit, Try again!", color: "error" });
      });
  };

  const CardSelector = ({ src, text, item }) => {
    return (
      <div
        onClick={() => setSelected(item)}
        style={{
          border: "2px solid #ccc",
          padding: 4,
          cursor: "pointer",
          borderRadius: 8,
        }}
      >
        <img
          width={200}
          height={50}
          style={{ objectFit: "contain", margin: 16 }}
          src={src}
        />
        <h6 style={{ padding: 4, textAlign: "center" }}>{text}</h6>
      </div>
    );
  };

  const WebsiteHandler = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: 16,
            columnGap: 16,
          }}
        >
          {typeList?.map((item, index) => {
            return (
              <CardSelector item={index} src={item.src} text={item.label} />
            );
          })}
        </div>
      </div>
    );
  };

  const DataHandler = ({ data, setData }) => {
    let oldDataX = JSON.parse(JSON.stringify(dataX));
    let oldData =
      typeList[selected]?.inputs?.length > 1
        ? oldDataX?.[typeList[selected]?.inputs[0]]?.[
            typeList[selected]?.inputs[1]
          ]
        : oldDataX?.[typeList[selected]?.inputs[0]];

    const [selectedMenu, setSelectedMenu] = useState(
      typeList[selected]?.type === "array"
        ? data?.[0]?.title
        : Object.keys(data)[0]
    );

    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(
      typeList[selected]?.type === "array"
        ? data?.map((e) => e?.title)?.length || 0
        : 0
    );
    const [changedData, setChangedData] = useState(
      typeList[selected]?.type === "array" ? data?.map((e) => e?.title) : null
    );

    const onChange = (e) => {
      setChangedData(e);
      let new_data = [];
      e?.map((item) => {
        let foo = data?.find((e) => e?.title === item);
        if (foo) new_data.push(foo);
      });
      setData(new_data);
    };

    const handleArrayData = (e) => {
      let newData = [];
      data?.map((item) => {
        if (item?.title === selectedMenu) {
          newData.push({ ...item, data: e?.jsObject });
        } else {
          newData.push(item);
        }
      });
      setData(newData);
      setLoading(true);
    };

    const handleObjectData = (e) => {
      if (e?.jsObject) setData({ ...data, [selectedMenu]: e?.jsObject });
      setLoading(true);
    };

    const addNew = () => {
      let foo = prompt("Enter the title of the new menu item");
      if (foo) {
        let newData = [
          ...data,
          { title: foo, isExpandable: true, data: { [foo]: [{}] } },
        ];
        setData(newData);
        setChangedData([...changedData, foo]);
        setKey(newData?.length);
      }
    };

    const revert = () => {
      setData(
        typeList[selected]?.inputs?.length > 1
          ? oldDataX?.[typeList[selected]?.inputs[0]]?.[
              typeList[selected]?.inputs[1]
            ]
          : oldDataX?.[typeList[selected]?.inputs[0]]
      );
      setSelectedMenu(
        typeList[selected]?.type === "array"
          ? data?.[0]?.title
          : Object.keys(data)[0]
      );
      setLoading(false);
      setKey(
        typeList[selected]?.type === "array"
          ? data?.map((e) => e?.title)?.length || 0
          : 0
      );
      setChangedData(
        typeList[selected]?.type === "array" ? data?.map((e) => e?.title) : null
      );
    };

    return (
      <div>
        {typeList[selected]?.type === "text" ? (
          <div>
            <JSONInput
              height={"70vh"}
              width={"70vw"}
              theme="light"
              placeholder={data}
              onChange={() => setLoading(false)}
              onBlur={(e) => {
                setLoading(true);
                if (e?.jsObject) setData(e.jsObject);
              }}
            />
          </div>
        ) : typeList[selected]?.type === "array" ? (
          <div>
            <DragAndDrop
              key={key}
              finalSpaceCharacters={changedData}
              selected={selectedMenu}
              setSelected={setSelectedMenu}
              onChange={onChange}
            />
            <JSONInput
              height={"60vh"}
              width={"70vw"}
              theme="light"
              onChange={() => setLoading(false)}
              placeholder={
                data
                  ?.map((e) => (e?.title == selectedMenu ? e.data : null))
                  ?.filter((e) => e != null)?.[0]
              }
              onBlur={(e) => handleArrayData(e)}
            />
          </div>
        ) : typeList[selected]?.type === "object" ? (
          <div>
            <DragAndDrop
              finalSpaceCharacters={Object.keys(data)}
              selected={selectedMenu}
              setSelected={setSelectedMenu}
              onChange={() => alert("Changes not allowed")}
            />
            <JSONInput
              height={"60vh"}
              width={"70vw"}
              theme="light"
              onChange={() => setLoading(false)}
              placeholder={data?.[selectedMenu]}
              onBlur={handleObjectData}
            />
          </div>
        ) : null}
        <div style={{ height: 48, alignItems: "center", display: "flex" }}>
          {typeList[selected]?.type === "array" ? (
            <button
              onClick={addNew}
              style={{ cursor: "pointer", padding: 4, marginRight: 16 }}
            >
              add
            </button>
          ) : null}
          <button onClick={revert} style={{ cursor: "pointer", padding: 4 }}>
            reset
          </button>
          {loading ? (
            <h5 style={{ padding: 4, marginLeft: 16 }}>saved successfully!</h5>
          ) : (
            <button style={{ cursor: "pointer", padding: 4, marginLeft: 16 }}>
              save
            </button>
          )}
        </div>
        <div style={{ width: "70vw", maxHeight: "70vh", overflow: "scroll" }}>
          <ReactJsonViewCompare
            key={compareKey}
            oldData={
              typeList[selected]?.type === "text"
                ? oldData
                : typeList[selected]?.type === "array"
                ? oldData
                    ?.map((e) => (e?.title == selectedMenu ? e.data : null))
                    ?.filter((e) => e != null)?.[0]
                : oldData?.[selectedMenu]
            }
            newData={
              typeList[selected]?.type === "text"
                ? data
                : typeList[selected]?.type === "array"
                ? data
                    ?.map((e) => (e?.title == selectedMenu ? e?.data : null))
                    ?.filter((e) => e != null)?.[0]
                : data?.[selectedMenu]
            }
          />
        </div>
      </div>
    );
  };

  const SelectedBox = ({ onClick }) => {
    let changedData = JSON.parse(JSON.stringify(finalData));
    const [data, setData] = useState(
      typeList[selected]?.inputs?.length > 1
        ? changedData?.[typeList[selected]?.inputs[0]]?.[
            typeList[selected]?.inputs[1]
          ]
        : changedData?.[typeList[selected]?.inputs[0]]
    );

    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon
              onClick={() => setSelected(null)}
              style={{ cursor: "pointer" }}
              fontSize="medium"
            >
              arrow_back
            </Icon>
            <h4 style={{ marginLeft: 8 }}>{typeList?.[selected]?.label}</h4>
          </div>
          <h5
            onClick={() => onClick(data)}
            style={{
              marginLeft: 8,
              cursor: "pointer",
              backgroundColor: "#89203A",
              width: 100,
              color: "white",
              borderRadius: 4,
              textAlign: "center",
            }}
          >
            {"Done"}
          </h5>
        </div>
        <div style={{ margin: 16 }}>
          <DataHandler data={data} setData={setData} />
        </div>
      </div>
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar isProd={env=='production'} />
      <MDBox px={5} py={3}>
        <Grid container spacing={3}>
          <Grid item>
            {selected != null ? (
              <SelectedBox onClick={onClick} />
            ) : (
              <div>
                <WebsiteHandler />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 24,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MDButton onClick={()=>onSubmit()} color={statusData?.color}>
                      {statusData?.text}
                    </MDButton>
                  </div>
                  <MDButton
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                    color={"dark"}
                  >
                    {editMode ? "Compare" : "Edit"}
                  </MDButton>
                </div>

                {showProd ? (
                  <div>
                    <MDBox
                      component="li"
                      display="flex"
                      marginTop={2}
                      alignItems="center"
                    >
                      <MDInput
                        value={uName}
                        onChange={(e) => setUName(e.target.value)}
                        style={{ marginRight: 10 }}
                        placeholder="Enter username"
                      />
                      <MDInput
                        type="password"
                        value={uPassword}
                        onChange={(e) => setUPassword(e.target.value)}
                        placeholder="Enter password"
                      />
                      <MDButton
                        style={{ marginLeft: 8 }}
                        onClick={moveToProd}
                        color={"info"}
                      >
                        {"Move to production"}
                      </MDButton>
                    </MDBox>
                  </div>
                ) : null}
                <div style={{ marginTop: 24 }}>
                  <MDTypography
                    color={showProd ? "error" : "info"}
                    fontWeight={"bold"}
                  >
                    {"Changes will be made to " + env}
                  </MDTypography>
                </div>
                <div
                  style={{ marginTop: 24, height: "70vh", overflow: "scroll" }}
                >
                  {editMode ? (
                    <JSONInput
                      height={"60vh"}
                      width={"100%"}
                      theme="light"
                      placeholder={finalData}
                      onBlur={(e) => {
                        if (e?.jsObject) {
                          setFinalData(e?.jsObject);
                        }
                      }}
                    />
                  ) : (
                    <ReactJsonViewCompare
                      key={compareKey}
                      oldData={{ ...dataX }}
                      newData={{ ...finalData }}
                    />
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
