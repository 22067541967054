import axios from "axios";

export const doLogin = (creds) => {
  var data = {
    "username": creds.username,
    "password": creds.password,
  };
  var config = {
    method: "post",
    url: `https://super-lambda.flyotech.com/super-lambda-api-apiWrapperLambdaFunction-NKQl56ToZTwm?type=${creds.type}`,
    headers: {
      "api_key": "1234!@#$%&",
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
};

export const getUserInfo = (token,type) => {
  var data = JSON.stringify({
    "token":token,
  });

  var config = {
    method: "post",
    url: `https://super-lambda.flyotech.com/super-lambda-api-apiWrapperLambdaFunction-NKQl56ToZTwm?type=${type}`,
    headers: {
      "api_key": "1234!@#$%&",
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
};
