// Material Dashboard 2 React layouts
import Profile from "layouts/profile";
// @mui icons
import Icon from "@mui/material/Icon";
import Production from "layouts/production";
import Upload from "layouts/upload";
import ImageMapper from "layouts/upload/ImageMapper";
import InfluencerStore from "layouts/upload/InfluencerStore";
import Personalized from "layouts/upload/Personalized";
import Quiz from "layouts/upload/Quiz";
import { WebsiteData } from "layouts/upload/WebsiteData";
import LayoutManager from "layouts/upload/LayoutManager";
// import ApiStore from "layouts/ApiStore";
// import Tables from "layouts/tables";

const routes = [
  {
    type: "collapse",
    name: "Brand & Category",
    key: "brand_and_category",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/brand_and_category",
    component: (
      <Upload
        type="brand_and_category"
        options={[
          { label: "Brand", value: "Brand", util: "Brand_Category" },
          { label: "Category", value: "Category", util: "Brand_Category" },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Catelog",
    key: "product",
    icon: <Icon fontSize="small">upload</Icon>,
    route: "/product",
    component: (
      <Upload
        type="product"
        options={[{ label: "Product", value: "Product", util: "product" }]}
        allowParams={true}
        hasColumnInputs={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Sorting & Pricing",
    key: "sorting_and_pricing",
    icon: <Icon fontSize="small">sort</Icon>,
    route: "/sorting_and_pricing",
    component: (
      <Upload
        type="sorting_and_pricing"
        options={[
          { label: "Sorting", value: "sorting", util: "sorting_and_pricing" },
          { label: "Discount", value: "discount", util: "sorting_and_pricing" },
        ]}
        allowParams={true}
      />
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Inventory",
  //   key: "inventory",
  //   icon: <Icon fontSize="small">inventory</Icon>,
  //   route: "/inventory",
  //   component: (
  //     <Upload
  //       type="inventory"
  //       options={[]}
  //       allowParams={false}
  //     />
  //   ),
  // },
  {
    type: "collapse",
    name: "Product variant",
    key: "product_variant",
    icon: <Icon fontSize="small">square</Icon>,
    route: "/product_variant",
    component: (
      <Upload
        type="product_variants"
        options={[
          {
            label: "Product variant",
            value: "product_variants",
            util: "product_variant",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Image Upload",
    key: "image_upload",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/image_upload",
    component: (
      <Upload
        type="products_image"
        options={[
          {
            label: "Image",
            value: "image_config",
            util: "image_config",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Feed Upload",
    key: "feed_content",
    icon: <Icon fontSize="small">bolt</Icon>,
    route: "/feed_content",
    component: (
      <Upload
        type="feed_content"
        options={[
          {
            label: "Feed",
            value: "feed_content",
            util: "feed_content",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Static Feed",
    key: "static_feed_content",
    icon: <Icon fontSize="small">tableChart</Icon>,
    route: "/static_feed_content",
    component: (
      <Upload
        type="static_feed_content"
        options={[
          {
            label: "Static Feed",
            value: "static_feed_content",
            util: "static_feed_content",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Video Upload",
    key: "video_upload",
    icon: <Icon fontSize="small">play_circle</Icon>,
    route: "/video_upload",
    component: (
      <Upload
        type="products_video"
        options={[
          {
            label: "1:1",
            value: "1:1",
            util: "1:1",
          },
          {
            label: "3:4",
            value: "3:4",
            util: "3:4",
          },
          {
            label: "9:16",
            value: "9:16",
            util: "9:16",
          },
        ]}
        allowParams={false}
        isVideoUpload={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Usecase",
    key: "usecase",
    icon: <Icon fontSize="small">handyman</Icon>,
    route: "/usecase",
    component: (
      <Upload
        type="usecases"
        options={[
          {
            label: "Usecase",
            value: "usecase",
            util: "usecase",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Brand Collection",
    key: "brand_collection",
    icon: <Icon fontSize="small">storefront</Icon>,
    route: "/brand_collection",
    component: (
      <Upload
        type="brand_collection"
        options={[
          {
            label: "Brand Collection",
            value: "brand_collection",
            util: "brand_collection",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Product Collection",
    key: "product_collections",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/product_collections",
    component: (
      <Upload
        type="product_collections"
        options={[
          {
            label: "Product Collection",
            value: "product_collections",
            util: "product_collections",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Homepage Component",
    key: "homepage_component",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/homepage_component",
    component: (
      <Upload
        type="homepage_component"
        options={[
          {
            label: "Homepage Component",
            value: "homepage_component",
            util: "homepage_component",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Home Comp Element",
    key: "homepage_component_element",
    icon: <Icon fontSize="small">window</Icon>,
    route: "/homepage_component_element",
    component: (
      <Upload
        type="homepage_component_element"
        options={[
          {
            label: "Homepage Component Element",
            value: "homepage_component_element",
            util: "homepage_elements",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Coupon",
    key: "coupon",
    icon: <Icon fontSize="small">percent</Icon>,
    route: "/coupon",
    component: (
      <Upload
        type="coupon"
        options={[
          {
            label: "Coupon",
            value: "coupon",
            util: "coupons",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Postal Code",
    key: "postal_code",
    icon: <Icon fontSize="small">my_location</Icon>,
    route: "/postal_code",
    component: (
      <Upload
        type="postal_code"
        options={[
          {
            label: "Postal Code",
            value: "postal_code",
            util: "postal_code",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Quick Filters",
    key: "quick_filters",
    icon: <Icon fontSize="small">filter_list</Icon>,
    route: "/quick_filters",
    component: (
      <Upload
        type="quick_filters"
        options={[
          {
            label: "Quick Filters",
            value: "quick_filters",
            util: "quick_filters",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Stories",
    key: "stories",
    icon: <Icon fontSize="small">auto_stories</Icon>,
    route: "/stories",
    component: (
      <Upload
        type="stories"
        options={[
          {
            label: "Stories",
            value: "stories",
            util: "stories",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Multi Stories",
    key: "multi_stories",
    icon: <Icon fontSize="small">auto_stories</Icon>,
    route: "/multi_stories",
    component: (
      <Upload
        type="multi_stories"
        options={[
          {
            label: "Multi Stories",
            value: "multi_stories",
            util: "multi_stories",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Whatsapp Gupshup",
  //   key: "gupshup_whatsapp_numbers",
  //   icon: <Icon fontSize="small">auto_stories</Icon>,
  //   route: "/gupshup_whatsapp_numbers",
  //   component: (
  //     <Upload
  //       type="multi_stories"
  //       options={[
  //         {
  //           label: "Whatsapp Gupshup",
  //           value: "gupshup_whatsapp_numbers",
  //           util: "gupshup_whatsapp",
  //         },
  //       ]}
  //       allowParams={true}
  //     />
  //   ),
  // },
  {
    type: "collapse",
    name: "Product Reviews",
    key: "product_review",
    icon: <Icon fontSize="small">reviews</Icon>,
    route: "/product_review",
    component: (
      <Upload
        type="product_review"
        options={[
          {
            label: "Product Reviews",
            value: "product_review",
            util: "product_review",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Rate Breakup",
    key: "rate_breakup",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/rate_breakup",
    component: (
      <Upload
        type="rate_breakup"
        options={[
          {
            label: "Rate Breakup",
            value: "rate_breakup",
            util: "rate_breakup",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Sorting Product",
    key: "sorting_product_output",
    icon: <Icon fontSize="small">sort</Icon>,
    route: "/sorting_product_output",
    component: (
      <Upload
        type="sorting_product_output"
        options={[
          {
            label: "Sorting Product",
            value: "sorting_product_output",
            util: "sorting_product_output",
          },
        ]}
        allowParams={false}
        accept=".xlsx"
      />
    ),
  },
  {
    type: "collapse",
    name: "Quick Banners",
    key: "quick_banners",
    icon: <Icon fontSize="small">ad_units</Icon>,
    route: "/quick_banners",
    component: (
      <Upload
        type="quick_banners"
        options={[
          {
            label: "Quick Banners",
            value: "quick_banners",
            util: "quick_banners",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/inventory",
    component: (
      <Upload
        type="inventory"
        options={[
          {
            label: "Inventory",
            value: "inventory",
            util: "inventory",
          },
        ]}
        allowParams={false}
      />
    ),
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blog_page",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/blog_page",
    component: (
      <Upload
        type="blog_page"
        options={[
          {
            label: "Blogs",
            value: "blog_page",
            util: "blog_page",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "QnA",
    key: "product_qnas",
    icon: <Icon fontSize="small">question_answer</Icon>,
    route: "/product_qnas",
    component: (
      <Upload
        type="product_qnas"
        options={[
          {
            label: "QnA",
            value: "product_qnas",
            util: "product_qnas",
          },
        ]}
        allowParams={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Website",
    key: "website",
    icon: <Icon fontSize="small">web</Icon>,
    route: "/website",
    component: <WebsiteData />,
  },
  {
    type: "collapse",
    name: "Influencer Store",
    key: "influencer_store",
    icon: <Icon fontSize="small">interests</Icon>,
    route: "/influencer_store",
    component: <InfluencerStore />,
  },
  {
    type: "collapse",
    name: "Quiz",
    key: "quiz",
    icon: <Icon fontSize="small">quiz</Icon>,
    route: "/quiz",
    component: <Quiz />,
  },
  {
    type: "collapse",
    name: "Personalized",
    key: "personalized",
    icon: <Icon fontSize="small">shopping_bag</Icon>,
    route: "/personalized",
    component: <Personalized />,
  },
  {
    type: "collapse",
    name: "Layout Manager",
    key: "layout_manager",
    icon: <Icon fontSize="small">layers_outlined</Icon>,
    route: "/layout_manager",
    component: <LayoutManager />,
  },
  {
    type: "collapse",
    name: "Image Mapper",
    key: "image_mapper",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/image_mapper",
    component: <ImageMapper />,
  },
  // {
  //   type: "collapse",
  //   name: "Change log",
  //   key: "changelog",
  //   icon: <Icon fontSize="small">difference</Icon>,
  //   route: "/changelog",
  //   component: <DropZoneCSV />,
  // },
  {
    type: "collapse",
    name: "Production",
    key: "production",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/production",
    component: <Production />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
