import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Select from "react-select";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "100px",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: "#999",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#555",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function index(props) {
  const [files, setFiles] = useState([]);
  const [filesKey, setFilesKey] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const uploadFiles = () => {
    files.forEach((file) => props.onUpload(file));
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props?.accept
      ? props?.accept
      : props.isVideoUpload
      ? ".mp4"
      : ".csv",
  });
  // ".mp4, .avl, .mkv, .webm, .m4v, .mpg, .mpeg, .amv, .mov"
  const removeFile = (index) => {
    files.splice(index, 1);
    setFilesKey(filesKey + 1);
  };
  const thumbs =
    files &&
    files.map((file, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: 10,
        }}
      >
        <Icon fontSize="small">attachment</Icon>
        <h6>{file.name}</h6>
        {props.isVideoUpload ? (
          <div>
            <Icon
              style={{
                backgroundColor: "#fff",
                borderRadius: 100,
                cursor: "pointer",
              }}
              onClick={() =>
                props.videoRes.failure.includes(file?.name)
                  ? props.onUpload(file)
                  : removeFile(index)
              }
              fontSize="small"
              color={
                props.videoRes.success.includes(file?.name)
                  ? "success"
                  : props.videoRes.failure.includes(file?.name)
                  ? "error"
                  : props.videoRes.uploading.includes(file?.name)
                  ? "info"
                  : "dark"
              }
            >
              {props.videoRes.success.includes(file?.name)
                ? "check"
                : props.videoRes.failure.includes(file?.name)
                ? "replay"
                : props.videoRes.uploading.includes(file?.name)
                ? "upload"
                : "remove"}
            </Icon>
          </div>
        ) : (
          <Icon
            style={{
              backgroundColor: "#3333",
              borderRadius: 100,
              cursor: "pointer",
            }}
            onClick={() => removeFile(index)}
            fontSize="small"
            color="error"
          >
            remove
          </Icon>
        )}
      </div>
    ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <section>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div className="p-5">
                  <h5 style={{ padding: 10 }}>
                    Drag 'n' drop some files here, or click to select files
                  </h5>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </MDBox>
      <aside key={filesKey}>{thumbs}</aside>
      <br />
      {props.allowParams ? (
        <div>
          <MDInput
            style={{ width: "100%", margin: 5 }}
            label="Enter row no from where update will take place (default: 2)"
            value={props.fromIndex}
            onChange={props.setFromIndex}
            type={"number"}
          />
          <br />
          <MDInput
            style={{ width: "100%", margin: 5 }}
            label="Enter row no to which update will take place (default: 5000)"
            value={props.toIndex}
            onChange={props.setToIndex}
            type={"number"}
          />
          <br />
          <MDInput
            style={{ width: "100%", margin: 5 }}
            label="Enter row no to update (comma-separated)"
            value={props.selectedIndex}
            onChange={props.setSelectedIndex}
          />
          <br />
          {props?.hasColumnInputs ? (
            <MDInput
              style={{ width: "100%", margin: 5 }}
              label="Enter column to update (column name)"
              value={props.selectedColumn}
              onChange={props.setSelectedColumn}
            />
          ) : null}
          <br />
        </div>
      ) : null}
      <div style={{ padding: 5 }}>
        <Select
          key={props.dropKey}
          value={props.selected}
          onChange={props.onSelect}
          options={props.options}
        />
        <MDButton
          style={{ marginTop: 10 }}
          onClick={uploadFiles}
          disabled={!files.length}
          variant="gradient"
          color="info"
        >
          Upload
        </MDButton>
      </div>
    </section>
  );
}
