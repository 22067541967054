export const actionTypes = {
  UPDATE_AUTH_TOKEN: "UPDATE_AUTH_TOKEN",
  UPDATE_SIGNIN_STATUS: "UPDATE_SIGNIN_STATUS",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  GET_ALL_BRANDS: "GET_ALL_BRANDS",
  SET_CHANGELOG_DATA: "SET_CHANGELOG_DATA",
  ADD_CHANGELOG_DATA: "ADD_CHANGELOG_DATA",
  UPDATE_CHANGELOG_DATA: "UPDATE_CHANGELOG_DATA",
  REMOVE_CHANGELOG_DATA: "REMOVE_CHANGELOG_DATA",
};
export default actionTypes;
