import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import SignIn from "./layouts/authentication/sign-in";
import { doLogin, getUserInfo } from "./layouts/authentication/API";
import Loader from "react-loader-spinner";
import {
  updateAuthToken,
  updateUserData,
  updateUserSignin,
} from "./layouts/authentication/UserActions";
import { useDispatch } from "react-redux";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import Sidenav from "examples/Sidenav";
import { store } from "store";
import { setAPIToken } from "API";

export default function RouterContainer() {
  const dispatch = useDispatch();
  const [controller, dispatchMouse] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    sidenavColor,
    brandWhite,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatchMouse, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatchMouse, true);
      setOnMouseEnter(false);
    }
  };
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(true);
  const signIn = () => {
    setLoading(true);
    let creds = {
      username: name,
      password: pass,
      type: "login_staging",
    };
    doLogin(creds)
      .then((res) => {
        const accessToken = res.data?.access_token;
        setAPIToken(accessToken);
        dispatch(updateAuthToken(accessToken));
        dispatch(updateUserSignin(true));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
        updateUser();
      });
  };
  const updateUser = () => {
    let token = store.getState().usersReducer.authToken;
    if (token && token != "") {
      getUserInfo(token, "profile_staging")
        .then((res) => {
          dispatch(updateUserData({ userData: res.data }));
          setIsUserSignedIn(true);
        })
        .catch((err) => {
          setIsUserSignedIn(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (store.getState().usersReducer.authToken) {
      updateUser();
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, []);

  return (
    <div>
      {!loading && layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={brandWhite}
          brandName="Mabel jewelry"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {loading ? (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <h1 style={{ position: "absolute", top: "20%" }}>Mabel</h1>
          <Loader
            style={{ position: "absolute", top: "40%" }}
            type="BallTriangle"
            color="#000"
            height={150}
            width={150}
          />
        </div>
      ) : !isUserSignedIn ? (
        <SignIn
          signIn={signIn}
          name={name}
          pass={pass}
          loading={isLoading}
          setLoading={setIsLoading}
          setName={(e) => setName(e.target.value)}
          setPass={(e) => setPass(e.target.value)}
        />
      ) : (
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/brand_and_category" />} />
        </Routes>
      )}
    </div>
  );
}
