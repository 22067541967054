import { actionTypes } from "../../constants/ActionTypes";

const initialState = {
  brands: [],
};

const BrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    default:
      return state;
  }
};

export default BrandReducer;
