import { Email, Female, Instagram, Male, Phone } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { doLogin } from "layouts/authentication/API";
import { useCallback, useState } from "react";

const InfluencerStore = () => {
  const [env, setEnv] = useState("production");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedPageSize, setSelectedPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [approved, setApproved] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [token, setToken] = useState(null);
  const [uname, setUname] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [approving, setApproving] = useState("");

  const getInfluecers = async (page, pageSize, token = token) => {
    setLoading(true);
    var config = {
      method: "get",
      url: `https://commerce.flyotech.com/v1/user/influencer/get_all_unapproved?page=${page}&limit=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (page > 1) {
          setData([...data, ...response?.data?.data]);
        } else {
          setData(response?.data?.data);
        }
        if (response?.data?.data?.length < pageSize) {
          setHasNextPage(false);
        }
      })
      .catch(function (error) {
        setError(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { Header: "", accessor: "profile_image", width: "10%", align: "center" },
    { Header: "Name", accessor: "full_name", width: "25%", align: "left" },
    {
      Header: "Contact",
      accessor: "contact",
      width: "10%",
      align: "left",
    },
    { Header: "Gender", accessor: "gender", align: "center", width: "5%" },
    {
      Header: "Handle",
      accessor: "influencer_handle",
      align: "center",
      width: "15%",
    },
    {
      Header: "username",
      accessor: "username",
      align: "center",
      width: "10%",
    },
    {
      Header: "Instagram",
      accessor: "instagram_id",
      align: "center",
    },
    {
      Header: "Created",
      accessor: "created_on",
      align: "center",
      sort: true,
      width: "20%",
    },
    { Header: "Approve", accessor: "approve", align: "center", width: "10%" },
  ];

  const handleApprove = async (user) => {
    setApproving(user);
    var config = {
      method: "post",
      url: `https://commerce.flyotech.com/v1/user/influencer/approve?username=${user}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.internal_response_code == 0) {
          setApproved([...approved, user]);
        } else {
          alert("Something went wrong");
        }
      })
      .catch(function (error) {
        alert(error?.message);
      })
      .finally(() => {
        setApproving("");
      });
  };

  const onLoadMore = () => {
    setPage(page + 1);
    getInfluecers(page + 1, selectedPageSize);
  };

  const rows = data?.map((item, index) => {
    return {
      profile_image: (
        <img
          src={item?.profile_image}
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            objectFit: "cover",
            border: "2px solid black",
          }}
        />
      ),
      full_name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item?.full_name?.first_name} {item?.full_name?.last_name}
        </MDTypography>
      ),
      contact: (
        <div>
          <a style={{ paddingRight: 16 }} href={`tel://${item?.mobile_number}`}>
            <Phone style={{ width: 20, height: 20 }} color="primary" />
          </a>
          <a href={`mailto:${item?.email}`}>
            <Email style={{ width: 20, height: 20 }} color={"secondary"} />
          </a>
        </div>
      ),
      gender:
        item?.gender?.toLowerCase() == "male" ? (
          <Male style={{ width: 20, height: 20 }} color={"info"} />
        ) : (
          <Female style={{ width: 20, height: 20 }} color={"error"} />
        ),
      instagram_id: item?.instagram_id ? (
        <a
          href={`https://www.instagram.com/${item?.instagram_id}/`}
          target="_blank"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Instagram style={{ marginRight: 4 }} color={"error"} />
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item?.instagram_id}
          </MDTypography>
        </a>
      ) : null,
      influencer_handle: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item?.influencer_handle || "N/A"}
        </MDTypography>
      ),
      username: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item?.username || "N/A"}
        </MDTypography>
      ),
      created_on: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {new Date(item?.created_on).toLocaleDateString()}
        </MDTypography>
      ),
      approve: (
        <div
          onClick={() =>
            approving == item?.username || approved?.includes(item?.username)
              ? {}
              : handleApprove(item?.username)
          }
        >
          <MDButton
            variant={approved?.includes(item?.username) ? "gradient" : "text"}
            color={approved?.includes(item?.username) ? "success" : "info"}
            size="small"
            disabled={
              approving == item?.username || approved?.includes(item?.username)
            }
          >
            {approved?.includes(item?.username) ? "APPROVED" : "APPROVE"}
          </MDButton>
        </div>
      ),
    };
  });

  const InfluencerWrapper = useCallback(() => {
    return data?.length ? (
      <div>
        <DataTable
          usePagination={false}
          canSearch={false}
          showTotalEntries={true}
          table={{ columns: columns, rows: rows }}
          pagination={{ variant: "gradient", color: "info" }}
          noEndBorder={false}
        />

        {hasNextPage ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
            onClick={onLoadMore}
          >
            <MDButton variant={"gradient"} color={"info"} size="medium">
              {"Load more"}
            </MDButton>
          </div>
        ) : null}
      </div>
    ) : (
      <MDTypography class="h1" color="info" fontWeight="bold">
        {"No pending requests..."}
      </MDTypography>
    );
  }, [data, hasNextPage, approved, approving]);

  const LoginProd = () => {
    let creds = {
      username: uname,
      password: password,
      type: "login_prod",
    };
    return doLogin(creds);
  };

  const autoApprove = () => {
    if (!user) return null;

    let url = `https://commerce.flyotech.com/v1/influencer/store/allow_influencer?mobile=+91${user}`;

    if (user?.includes("@")) {
      url = `https://commerce.flyotech.com/v1/influencer/store/allow_influencer?email=${user}`;
    }
    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.internal_response_code == 0) {
          alert(`Approved! ${user}`);
        } else {
          alert("Something went wrong");
        }
      })
      .catch(function (error) {
        alert(error?.message);
      });
  };

  const onLogin = () => {
    LoginProd()
      .then(async (res) => {
        let token = res?.data?.access_token;
        await setToken(token);
        getInfluecers(1, selectedPageSize, token);
      })
      .catch((e) => {
        alert("Failed to login to production! Wrong credentials?");
        console.log(e);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox px={5} py={3}>
        <Grid container spacing={3}>
          <Grid item>
            {!token?.length ? (
              <div>
                <MDBox
                  component="li"
                  display="flex"
                  marginTop={2}
                  alignItems="center"
                >
                  <MDInput
                    value={uname}
                    onChange={(e) => setUname(e.target.value)}
                    style={{ marginRight: 10 }}
                    placeholder="Enter username"
                  />
                  <MDInput
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                  />
                  <MDButton
                    style={{ marginLeft: 8 }}
                    onClick={onLogin}
                    color={"info"}
                  >
                    {"LOGIN"}
                  </MDButton>
                </MDBox>
              </div>
            ) : loading ? (
              <p class="h1">{"Fetching Data..."}</p>
            ) : (
              <div>
                <div
                  style={{
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <MDInput
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                      style={{ marginRight: 10, width: 280 }}
                      placeholder="Enter username"
                    />
                    <MDButton
                      style={{ marginLeft: 8, marginTop: 4 }}
                      onClick={autoApprove}
                      color={"info"}
                    >
                      {"APPROVE"}
                    </MDButton>
                  </div>
                  <MDButton
                    style={{ marginLeft: 8 }}
                    onClick={() => getInfluecers(1, selectedPageSize, token)}
                    color={"info"}
                  >
                    <Icon style={{ marginRight: 4 }}>refresh</Icon>
                    REFRESH
                  </MDButton>
                </div>
                <InfluencerWrapper />
              </div>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default InfluencerStore;
