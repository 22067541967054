import {
  ArrowBack,
  CloudUploadRounded,
  Delete,
  Edit,
  FindInPage,
  Link,
  PlusOne,
  RefreshOutlined,
  SwitchAccount,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import axios from "axios";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { doLogin } from "layouts/authentication/API";
import { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import Loader from "react-loader-spinner";
import { store } from "store";

const Personalized = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [customData, setCustomData] = useState({});
  const [tab, setTab] = useState(0);
  const [keyVal, setKeyVal] = useState(0);
  const [isProd, setIsProd] = useState(false);
  const [url, setUrl] = useState("https://commerce-staging.flyotech.com");
  const [apiToken, setAPIToken] = useState(null);

  const getAllQuiz = () => {
    setUrl(isProd ? "https://commerce.flyotech.com" : "https://commerce-staging.flyotech.com");
    setLoading(true);
    let token = store.getState().usersReducer.authToken;
    var config = {
      method: "get",
      url: `${url}/v1/personalization/get_all_personalization`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isProd ? apiToken : token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setData(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteQuiz = (id) => {
    let prompt = window.prompt(`Are you sure you want to delete this customization? Please enter "${id}" to confirm.`);
    if (prompt == id) {
      setLoading(true);
      let token = store.getState().usersReducer.authToken;
      var config = {
        method: "post",
        url: `${url}/v1/personalization/delete_personalization?code=${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${isProd ? apiToken : token}`,
        },
      };
      axios(config)
        .then(function (response) {
          getAllQuiz();
        })
        .catch(function (error) {
          console.log(error?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getQuizByCode = (code) => {
    setLoading(true);
    let token = store.getState().usersReducer.authToken;
    var config = {
      method: "get",
      url: `${url}/v1/personalization/get_personalization_by_code?code=${code}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isProd ? apiToken : token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.internal_response_code == 0) {
          setMode("edit");
          setCustomData(response?.data?.data);
        } else {
          alert("Quiz not found");
        }
      })
      .catch(function (error) {
        console.log(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchByCode = () => {
    let code = window.prompt("Enter customization code");
    if (code) {
      getQuizByCode(code);
    }
  };

  const LoginProd = (uname, password) => {
    let creds = {
      username: uname,
      password: password,
      type: "login_prod",
    };
    return doLogin(creds);
  };

  useEffect(() => {
    if (isProd) {
      let uname = window.prompt("Enter username");
      let password = window.prompt("Enter password");
      LoginProd(uname, password)
        .then((res) => {
          const accessToken = res.data?.access_token;
          if (!accessToken) {
            alert("Invalid credentials, Unable to login, Switching back to staging!");
            setIsProd(false);
            setUrl("https://commerce-staging.flyotech.com");
          } else {
            setUrl("https://commerce.flyotech.com");
            setAPIToken(accessToken);
            setTimeout(() => {
              getAllQuiz();
            }, 300);
          }
        })
        .catch((err) => {
          alert(err);
          setIsProd(false);
          setUrl("https://commerce-staging.flyotech.com");
        });
    } else {
      setUrl("https://commerce-staging.flyotech.com");
      setTimeout(() => {
        getAllQuiz();
      }, 300);
    }
  }, [isProd]);

  const updateCta = (value) => {
    let newQuizData = { ...customData };
    newQuizData.steps[tab].cta = value?.target?.value;
    setCustomData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const updateSubTitle = (value) => {
    let newQuizData = { ...customData };
    newQuizData.steps[tab].sub_title = value?.target?.value;
    setCustomData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const updateTitle = (value) => {
    let newQuizData = { ...customData };
    newQuizData.steps[tab].title = value?.target?.value;
    setCustomData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const addNewStep = () => {
    let newQuizData = { ...customData };
    newQuizData.steps.push({
      title: "",
      sub_title: "",
      cta: "",
      link: "",
      filter: { product_type: [], category: [] },
    });
    setCustomData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const deleteStep = () => {
    let prompt = window.confirm("Are you sure you want to delete this step?");
    if (prompt) {
      let newQuizData = { ...customData };
      newQuizData.steps.splice(tab, 1);
      setCustomData(newQuizData);
      setKeyVal(keyVal + 1);
    }
  };

  const validateImage = (url) => {
    if (url?.startsWith("https://") || url?.startsWith("http://")) {
      return true;
    }
    return false;
  };

  const validateQuiz = (data) => {
    if (!data?.code || data?.code == "") {
      return "Please enter customization code";
    }
    if (!data?.steps || data?.steps?.length < 2) {
      return "Please add atleast 2 steps";
    }
    let validatedImage = validateImage(data?.banner);
    if (!validatedImage) {
      return "Please enter valid banner image url";
    }
    if (!data?.banner || data?.banner == "") {
      return "Please enter banner image url";
    }
    if (!data?.title || data?.title == "") {
      return "Please enter title";
    }
    for (let i = 0; i < data?.steps?.length; i++) {
      if (!data?.steps[i]?.title || data?.steps[i]?.title == "") {
        return "Please enter title for step " + (i + 1);
      }
      if (!data?.steps[i]?.sub_title || data?.steps[i]?.sub_title == "") {
        return "Please enter sub title for step " + (i + 1);
      }
      if (!data?.steps[i]?.cta || data?.steps[i]?.cta == "") {
        return "Please enter cta for step " + (i + 1);
      }
      if (!data?.steps[i]?.link || data?.steps[i]?.link == "") {
        return "Please enter link for step " + (i + 1);
      }
      if (!data?.steps[i]?.filter?.product_type?.length && !data?.steps[i]?.filter?.category?.length) {
        return "Please enter atleast one filter for step " + (i + 1);
      }
      data?.steps[i]?.filter?.product_type?.forEach((e) => {
        if (!e || e == "") {
          return "Please enter valid product type for step " + (i + 1);
        }
      });
      data?.steps[i]?.filter?.category?.forEach((e) => {
        if (!e || e == "") {
          return "Please enter valid category for step " + (i + 1);
        }
      });
    }
    return "";
  };

  const addNewQuiz = () => {
    setMode("add");
    setCustomData({
      code: "",
      banner: "",
      title: "",
      sub_title: "",
      cta: "",
      steps: [
        {
          title: "",
          sub_title: "",
          cta: "",
          link: "",
          filter: { product_type: [], category: [] },
        },
      ],
    });
  };

  const publishQuiz = () => {
    setLoading(true);
    let isValidated = validateQuiz(customData);
    if (isValidated != "") {
      alert(isValidated);
      setLoading(false);
      return;
    }
    let token = store.getState().usersReducer.authToken;
    var config = {
      method: "post",
      url:
        mode == "add"
          ? `${url}/v1/personalization/create_personalization`
          : `${url}/v1/personalization/update_personalization`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isProd ? apiToken : token}`,
      },
      data: customData,
    };
    axios(config)
      .then(function (response) {
        console.log(response?.data);
        if (response?.data?.internal_response_code == 0) {
          setMode("");
          setCustomData({});
          getAllQuiz();
        } else {
          alert(response?.data?.message);
        }
      })
      .catch(function (error) {
        console.log(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateFilters = (value) => {
    let newQuizData = { ...customData };
    newQuizData.steps[tab].filter = value?.jsObject;
    setCustomData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const updateLink = (value) => {
    let newQuizData = { ...customData };
    newQuizData.steps[tab].link = value?.target?.value;
    setCustomData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar isProd={isProd} />
      <MDBox px={5} py={3}>
        {mode == "" ? (
          <Grid container spacing={3}>
            <Grid item>
              <MDButton onClick={addNewQuiz} variant="contained" color="info">
                <PlusOne style={{ marginRight: 8 }} />
                {"Add New"}
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={searchByCode} variant="contained" color="dark">
                <FindInPage style={{ marginRight: 8 }} />
                {"fetch by code"}
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={getAllQuiz} variant="outlined" color="dark">
                <RefreshOutlined style={{ marginRight: 8 }} />
                {"Refresh"}
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={() => setIsProd(!isProd)} variant="contained" color="error">
                <SwitchAccount style={{ marginRight: 8 }} />
                {`SWITCH TO ${isProd ? "STAGING" : "PRODUCTION"}`}
              </MDButton>
            </Grid>
          </Grid>
        ) : null}
        {loading ? (
          <div style={{ marginTop: "20vh", justifyContent: "center", display: "flex" }}>
            <Loader type="Rings" color="#000" height={"30vh"} width={"30vw"} />
          </div>
        ) : mode == "add" || mode == "edit" ? (
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            <Grid item>
              <ArrowBack
                style={{
                  cursor: "pointer",
                  height: 40,
                  width: 40,
                  padding: 8,
                  marginRight: 8,
                  backgroundColor: "#fff",
                  color: "#000",
                  borderRadius: 8,
                }}
                onClick={() => setMode("")}
              />
              <MDInput
                label="Customization code"
                value={customData?.code}
                disabled={mode == "edit"}
                onChange={(e) => setCustomData({ ...customData, code: e?.target?.value })}
              />
              <Grid container spacing={3} style={{ marginTop: 4 }}>
                <Grid item>
                  <MDInput
                    label="Title"
                    value={customData?.title}
                    onChange={(e) => setCustomData({ ...customData, title: e?.target?.value })}
                  />
                </Grid>
                <Grid item>
                  <MDInput
                    label="Description"
                    value={customData?.sub_title}
                    onChange={(e) => setCustomData({ ...customData, sub_title: e?.target?.value })}
                  />
                </Grid>
                <Grid item>
                  <MDInput
                    label="Banner Image"
                    value={customData?.banner}
                    style={{ width: 400 }}
                    onChange={(e) => setCustomData({ ...customData, banner: e?.target?.value })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 4 }}>
                {customData?.steps?.map((step, index) => {
                  return (
                    <Grid item key={index}>
                      <MDButton
                        onClick={() => {
                          setTab(index);
                          setKeyVal(keyVal + 1);
                        }}
                        color={tab == index ? "info" : "dark"}
                      >{`Step ${index + 1}`}</MDButton>
                    </Grid>
                  );
                })}
                {customData?.steps?.length < 6 ? (
                  <Grid item>
                    <MDButton onClick={addNewStep} variant="outlined" color={"primary"}>{`+ Add Step`}</MDButton>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 12 }}>
                <Grid item>
                  <MDInput label="Title" value={customData?.steps?.[tab]?.title || ""} onChange={updateTitle} />
                </Grid>
                <Grid item>
                  <MDInput
                    label="SubTitle"
                    value={customData?.steps?.[tab]?.sub_title || ""}
                    onChange={updateSubTitle}
                  />
                </Grid>
                <Grid item>
                  <MDInput label="CTA" value={customData?.steps?.[tab]?.cta || ""} onChange={updateCta} />
                </Grid>
                <Grid item>
                  <MDInput label="Link" value={customData?.steps?.[tab]?.link || ""} onChange={updateLink} />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 8 }}>
                <Grid item>
                  <JSONInput
                    height={"200px"}
                    width={"800px"}
                    theme="dark_vscode_tribute"
                    placeholder={customData?.steps?.[tab]?.filter}
                    onBlur={updateFilters}
                    key={keyVal}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 8 }}>
                {customData?.steps?.[tab]?.products?.map((e, index) => {
                  return (
                    <Grid item key={index}>
                      <a href={`https://mabel.jewelry/a/b/c/${e?.sku_code}`} target="_blank">
                        <MDBadge badgeContent={e?.sku_code} circular={true} color="info" variant="gradient" size="lg" />
                      </a>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: 12, marginTop: 12 }}>
                <Grid item>
                  <MDButton onClick={deleteStep} color={"error"}>
                    <Delete style={{ marginRight: 8 }} />
                    {`DELETE STEP`}
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton onClick={publishQuiz} color={"success"}>
                    <CloudUploadRounded style={{ marginRight: 8 }} />
                    Publish Quiz
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            {(data || [])?.map((e, index) => {
              return (
                <Grid item key={index}>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      width: 200,
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img src={e?.banner} width={200} height={100} style={{ objectFit: "cover" }} />
                    <a
                      href={
                        isProd
                          ? `https://mabel.jewelry/customize/${e?.code}`
                          : `https://dev.mable.jewelry/customize/${e?.code}`
                      }
                      target="_blank"
                      style={{ position: "absolute", top: 4, right: 4 }}
                    >
                      <Link
                        style={{
                          color: "#fff",
                          padding: 4,
                          backgroundColor: "#1B72E8",
                          borderRadius: 14,
                          width: 24,
                          height: 24,
                        }}
                      />
                    </a>
                    <div style={{ padding: 8, paddingTop: 0 }}>
                      <div
                        style={{
                          color: "#000",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, fontWeight: "bold", fontFamily: "monospace" }}>
                          {e?.title?.toUpperCase()}
                        </div>
                      </div>
                      <div style={{ color: "#888", fontSize: 10 }}>{e?.steps?.length} steps</div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: 4,
                        }}
                      >
                        <Edit
                          style={{
                            color: "#1B72E8",
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                            padding: 4,
                            backgroundColor: "#1B72E822",
                            borderRadius: 12,
                          }}
                          onClick={() => getQuizByCode(e?.code)}
                        />
                        <Delete
                          style={{
                            color: "#c70000",
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                            padding: 4,
                            backgroundColor: "#c7000022",
                            borderRadius: 12,
                          }}
                          onClick={() => deleteQuiz(e?.code)}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default Personalized;
