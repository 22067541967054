import {
  ArrowBack,
  Delete,
  Edit,
  FindInPage,
  Link,
  PlusOne,
  RefreshOutlined,
  SwitchAccount,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { doLogin } from "layouts/authentication/API";
import { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import Loader from "react-loader-spinner";
import { store } from "store";

const Quiz = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [quizData, setQuizData] = useState({});
  const [tab, setTab] = useState(0);
  const [keyVal, setKeyVal] = useState(0);
  const [isProd, setIsProd] = useState(false);
  const [url, setUrl] = useState("https://commerce-staging.flyotech.com");
  const [apiToken, setAPIToken] = useState(null);

  const getAllQuiz = () => {
    setUrl(isProd ? "https://commerce.flyotech.com" : "https://commerce-staging.flyotech.com");
    setLoading(true);
    let token = store.getState().usersReducer.authToken;
    var config = {
      method: "get",
      url: `${url}/v1/quiz/get_all_quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isProd ? apiToken : token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setData(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteQuiz = (id) => {
    let prompt = window.prompt(`Are you sure you want to delete this quiz? Please enter "${id}" to confirm.`);
    if (prompt == id) {
      setLoading(true);
      let token = store.getState().usersReducer.authToken;
      var config = {
        method: "post",
        url: `${url}/v1/quiz/delete_quiz?code=${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${isProd ? apiToken : token}`,
        },
      };
      axios(config)
        .then(function (response) {
          getAllQuiz();
        })
        .catch(function (error) {
          console.log(error?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getQuizByCode = (code) => {
    setLoading(true);
    let token = store.getState().usersReducer.authToken;
    var config = {
      method: "get",
      url: `${url}/v1/quiz/get_quiz_by_name?code=${code}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isProd ? apiToken : token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.internal_response_code == 0) {
          setMode("edit");
          setQuizData(response?.data?.data);
        } else {
          alert("Quiz not found");
        }
      })
      .catch(function (error) {
        console.log(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchByCode = () => {
    let code = window.prompt("Enter quiz code");
    if (code) {
      getQuizByCode(code);
    }
  };

  const LoginProd = (uname, password) => {
    let creds = {
      username: uname,
      password: password,
      type: "login_prod",
    };
    return doLogin(creds);
  };

  useEffect(() => {
    if (isProd) {
      let uname = window.prompt("Enter username");
      let password = window.prompt("Enter password");
      LoginProd(uname, password)
        .then((res) => {
          const accessToken = res.data?.access_token;
          if (!accessToken) {
            alert("Invalid credentials, Unable to login, Switching back to staging!");
            setIsProd(false);
            setUrl("https://commerce-staging.flyotech.com");
          } else {
            setUrl("https://commerce.flyotech.com");
            setAPIToken(accessToken);
            setTimeout(() => {
              getAllQuiz();
            }, 300);
          }
        })
        .catch((err) => {
          alert(err);
          setIsProd(false);
          setUrl("https://commerce-staging.flyotech.com");
        });
    } else {
      setUrl("https://commerce-staging.flyotech.com");
      setTimeout(() => {
        getAllQuiz();
      }, 300);
    }
  }, [isProd]);

  const addNewImage = () => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].value.push({ image_link: "", value: "", title: "" });
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const updateCta = (value) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].cta = value?.target?.value;
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const updateKey = (value) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].key = value?.target?.value;
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const updateTitle = (value) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].title = value?.target?.value;
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const onImageUrlChange = (value, index) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].value[index].image_link = value?.target?.value;
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const onValueImageTitle = (value, index) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].value[index].title = value?.target?.value;
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const onValueChange = (value, index) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].value[index].value = value?.target?.value;
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const addNewStep = () => {
    let newQuizData = { ...quizData };
    newQuizData.steps.push({ key: "", title: "", cta: "", value: [{ image_link: "", value: "", title: "" }] });
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const deleteStep = () => {
    let prompt = window.confirm("Are you sure you want to delete this step?");
    if (prompt) {
      let newQuizData = { ...quizData };
      newQuizData.steps.splice(tab, 1);
      setQuizData(newQuizData);
      setKeyVal(keyVal + 1);
    }
  };

  const deleteImage = (index) => {
    let newQuizData = { ...quizData };
    newQuizData.steps[tab].value.splice(index, 1);
    setQuizData(newQuizData);
    setKeyVal(keyVal + 1);
  };

  const validateImage = (url) => {
    if (url?.startsWith("https://") || url?.startsWith("http://")) {
      return true;
    }
    return false;
  };

  const validateQuiz = (data) => {
    if (!data?.code || data?.code == "") {
      return "Please enter quiz code";
    }
    if (!data?.steps || data?.steps?.length < 3) {
      return "Please add atleast 3 steps";
    }
    for (let i = 0; i < data?.steps?.length; i++) {
      if (!data?.steps[i]?.key || data?.steps[i]?.key == "") {
        return "Please enter key for step " + (i + 1);
      }
      if (!data?.steps[i]?.title || data?.steps[i]?.title == "") {
        return "Please enter title for step " + (i + 1);
      }
      if (!data?.steps[i]?.value || data?.steps[i]?.value?.length == 0) {
        return "Please add atleast one image for step " + (i + 1);
      }
      for (let j = 0; j < data?.steps[i]?.value?.length; j++) {
        let validatedImage = validateImage(data?.steps[i]?.value[j]?.image_link);
        if (!validatedImage) {
          return "Please enter valid image url for step " + (i + 1) + " image " + (j + 1);
        }
        if (!data?.steps[i]?.value[j]?.image_link || data?.steps[i]?.value[j]?.image_link == "") {
          return "Please enter image url for step " + (i + 1) + " image " + (j + 1);
        }
        if (!data?.steps[i]?.value[j]?.title || data?.steps[i]?.value[j]?.title == "") {
          return "Please enter title for step " + (i + 1) + " image " + (j + 1);
        }
        if (!data?.steps[i]?.value[j]?.value || data?.steps[i]?.value[j]?.value == "") {
          return "Please enter value for step " + (i + 1) + " image " + (j + 1);
        }
      }
    }
    return "";
  };

  const addNewQuiz = () => {
    setMode("add");
    setQuizData({
      code: "",
      steps: [
        {
          key: "",
          title: "",
          cta: "",
          value: [{ image_link: "", value: "", title: "" }],
        },
      ],
    });
  };

  const publishQuiz = () => {
    setLoading(true);
    let isValidated = validateQuiz(quizData);
    if (isValidated != "") {
      alert(isValidated);
      setLoading(false);
      return;
    }
    let token = store.getState().usersReducer.authToken;
    var config = {
      method: "post",
      url: mode == "add" ? `${url}/v1/quiz/create_quiz` : `${url}/v1/quiz/update_quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${isProd ? apiToken : token}`,
      },
      data: quizData,
    };
    axios(config)
      .then(function (response) {
        console.log(response?.data);
        if (response?.data?.internal_response_code == 0) {
          setMode("");
          setQuizData({});
          getAllQuiz();
        } else {
          alert(response?.data?.message);
        }
      })
      .catch(function (error) {
        console.log(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox px={5} py={3}>
        {mode == "" ? (
          <Grid container spacing={3}>
            <Grid item>
              <MDButton onClick={addNewQuiz} variant="contained" color="info">
                <PlusOne style={{ marginRight: 8 }} />
                {"Add New Quiz"}
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={searchByCode} variant="contained" color="dark">
                <FindInPage style={{ marginRight: 8 }} />
                {"Get Quiz by code"}
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={getAllQuiz} variant="outlined" color="dark">
                <RefreshOutlined style={{ marginRight: 8 }} />
                {"Refresh"}
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={() => setIsProd(!isProd)} variant="contained" color="error">
                <SwitchAccount style={{ marginRight: 8 }} />
                {`SWITCH TO ${isProd ? "STAGING" : "PRODUCTION"}`}
              </MDButton>
            </Grid>
          </Grid>
        ) : null}
        {loading ? (
          <div style={{ marginTop: "20vh", justifyContent: "center", display: "flex" }}>
            <Loader type="Rings" color="#000" height={"30vh"} width={"30vw"} />
          </div>
        ) : mode == "add" || mode == "edit" ? (
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            <Grid item>
              <ArrowBack
                style={{
                  cursor: "pointer",
                  height: 40,
                  width: 40,
                  padding: 8,
                  marginRight: 8,
                  backgroundColor: "#fff",
                  color: "#000",
                  borderRadius: 8,
                }}
                onClick={() => setMode("")}
              />
              <MDInput
                label="Quiz code"
                value={quizData?.code}
                disabled={mode == "edit"}
                onChange={(e) => setQuizData({ ...quizData, code: e?.target?.value })}
              />
              <Grid container spacing={3} style={{ marginTop: 4 }}>
                {quizData?.steps?.map((step, index) => {
                  return (
                    <Grid item key={index}>
                      <MDButton onClick={() => setTab(index)} color={tab == index ? "info" : "dark"}>{`Step ${
                        index + 1
                      }`}</MDButton>
                    </Grid>
                  );
                })}
                {quizData?.steps?.length < 6 ? (
                  <Grid item>
                    <MDButton onClick={addNewStep} variant="outlined" color={"primary"}>{`+ Add Step`}</MDButton>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 12 }}>
                <Grid item>
                  <MDInput label="Key" value={quizData?.steps?.[tab]?.key || ""} onChange={updateKey} />
                </Grid>
                <Grid item>
                  <MDInput label="Title" value={quizData?.steps?.[tab]?.title || ""} onChange={updateTitle} />
                </Grid>
                <Grid item>
                  <MDInput label="CTA" value={quizData?.steps?.[tab]?.cta || ""} onChange={updateCta} />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 12 }}>
                <Grid item>
                  {quizData?.steps?.[tab]?.value?.map((option, index) => {
                    return (
                      <Grid container spacing={3} key={index} pt={1}>
                        <Grid item>
                          <MDInput
                            label="Image url"
                            value={option?.image_link || ""}
                            style={{ width: 400 }}
                            onChange={(e) => onImageUrlChange(e, index)}
                          />
                        </Grid>
                        <Grid item>
                          <MDInput
                            label="Title"
                            value={option?.title || ""}
                            onChange={(e) => onValueImageTitle(e, index)}
                          />
                        </Grid>
                        <Grid item>
                          <MDInput
                            label="Value"
                            value={option?.value || ""}
                            onChange={(e) => onValueChange(e, index)}
                          />
                        </Grid>
                        <Grid item>
                          <Delete
                            style={{
                              color: "#c70000",
                              cursor: "pointer",
                              backgroundColor: "#c7000011",
                              padding: 6,
                              height: 36,
                              width: 36,
                              borderRadius: 4,
                              marginTop: 4,
                              cursor: "pointer",
                            }}
                            onClick={() => deleteImage(index)}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container spacing={3} style={{ marginBottom: 12 }}>
                    {!quizData?.steps?.[tab]?.value || quizData?.steps?.[tab]?.value?.length < 6 ? (
                      <Grid item style={{ marginTop: 12 }}>
                        <MDButton onClick={addNewImage} variant="outlined" color={"info"}>{`+ Add Image`}</MDButton>
                      </Grid>
                    ) : null}
                    <Grid item style={{ marginTop: 12 }}>
                      <MDButton onClick={deleteStep} color={"error"}>
                        <Delete style={{ marginRight: 8 }} />
                        {`DELETE STEP`}
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            {(data || [])?.map((e, index) => {
              return (
                <Grid item key={index}>
                  <div style={{ backgroundColor: "#fff", padding: 8, borderRadius: 8, minWidth: 200 }}>
                    <div
                      style={{
                        color: "#000",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>{e?.code}</div>
                      <a
                        href={
                          isProd ? `https://mabel.jewelry/quiz/${e?.code}` : `https://dev.mable.jewelry/quiz/${e?.code}`
                        }
                        target="_blank"
                      >
                        <Link
                          style={{
                            color: "#1B72E8",
                            padding: 4,
                            backgroundColor: "#1B72E811",
                            borderRadius: 14,
                            width: 28,
                            height: 28,
                          }}
                        />
                      </a>
                    </div>
                    <div style={{ color: "#888", fontSize: 14 }}>{e?.steps?.length} steps</div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {e?.steps?.map((step, ind) => {
                        return (
                          <div key={ind} style={{ color: "#444", fontSize: 14, marginRight: 8 }}>
                            {ind < e?.steps?.length - 1 ? step?.title + " >" : step?.title}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 12,
                      }}
                    >
                      <Edit
                        style={{
                          color: "#1B72E8",
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                          padding: 4,
                          backgroundColor: "#1B72E822",
                          borderRadius: 12,
                        }}
                        onClick={() => getQuizByCode(e?.code)}
                      />
                      <Delete
                        style={{
                          color: "#c70000",
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                          padding: 4,
                          backgroundColor: "#c7000022",
                          borderRadius: 12,
                        }}
                        onClick={() => deleteQuiz(e?.code)}
                      />
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
        {mode == "add" || mode == "edit" ? (
          <div>
            <Grid container spacing={3} style={{ marginTop: 8, padding: 16, backgroundColor: "white" }}>
              <Grid item>
                <div style={{ fontSize: 18, fontWeight: "bold" }}>{quizData?.steps?.[tab]?.title}</div>
                <div style={{ fontSize: 12 }}>{quizData?.steps?.[tab]?.cta}</div>
                <Grid container spacing={3} style={{ marginTop: 12 }}>
                  {quizData?.steps?.[tab]?.value?.map((option, index) => {
                    return (
                      <Grid item key={index}>
                        <img src={option?.image_link} width={100} height={100} />
                        <div style={{ fontSize: 14 }}>{option?.title}</div>
                      </Grid>
                    );
                  })}
                </Grid>
                <MDButton onClick={publishQuiz} color={"success"} style={{ marginTop: 32 }} size={"large"}>
                  Publish Quiz
                </MDButton>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 8, padding: 16, backgroundColor: "black" }}>
              <Grid item>
                <JSONInput
                  height={"400px"}
                  width={"100%"}
                  theme="dark_vscode_tribute"
                  placeholder={quizData}
                  onBlur={(e) => setQuizData(e.jsObject)}
                  key={keyVal}
                />
              </Grid>
            </Grid>
          </div>
        ) : null}
      </MDBox>
    </DashboardLayout>
  );
};

export default Quiz;
