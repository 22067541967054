import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

function Invoices(props) {
  return !props.loading ? (
    <Card sx={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="info">
          {"Files in " + props.type.label}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {props.files.map((_) => {
            return (
              <Invoice
                key={_.Key}
                label={_.Key}
                date={_.LastModified}
                size={_.Size}
                type={props.type}
                masterData={props.masterData}
                allowParams={props.allowParams}
              />
            );
          })}
        </MDBox>
      </MDBox>
    </Card>
  ) : (
    <h1 style={{textAlign: 'center'}}>Loading please wait...</h1>
  );
}

export default Invoices;
