import axios from "axios";
import { updateLastAPIResponse } from "../layouts/authentication/UserActions";
import { appConstants } from "../constants/AppConstants";
import {store} from "../store";

const APIKit = axios.create({
  baseURL: appConstants.API_BASE,
  responseType: "json",
});

APIKit.defaults.timeout = 10000;

APIKit.interceptors.request.use((config) => {
  console.log("request:::", config);
  const state = store.getState();
  const accessToken = state.usersReducer.authToken;
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

APIKit.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response) {
      store.dispatch(updateLastAPIResponse(error?.response));
    } else if (error?.code === "ECONNABORTED") {
      const responseObj = {
        status: 10000,
        config: {
          url: "timeout",
        },
      };
      store.dispatch(updateLastAPIResponse(responseObj));
    }
    return Promise.reject(error);
  }
);

export const setAPIToken = (token) => {
  console.log("********************* token *************************");
  console.log(token);
  APIKit.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export default APIKit;
