import { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";
import { getUserInfo } from "layouts/authentication/API";
import {
  updateUserData,
  updateAuthToken,
  updateUserSignin,
} from "layouts/authentication/UserActions";
import { useDispatch } from "react-redux";
import { setAPIToken } from "API";
import MDButton from "components/MDButton";
import { store } from "store";
import Loader from "react-loader-spinner";

function Overview() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const GetUserInfo = () => {
    getUserInfo(store.getState().usersReducer.authToken, "profile_staging")
      .then((res) => {
        dispatch(updateUserData({ userData: res.data }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  const logout = () => {
    setAPIToken(null);
    dispatch(updateAuthToken(null));
    dispatch(updateUserSignin(false));
    dispatch(updateUserData({ userData: null }));
    window.location.reload(false);
  };
  useEffect(() => {
    GetUserInfo();
  }, []);
  return loading ? (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <h1 style={{ position: "absolute", top: "20%" }}>Mabel</h1>
      <Loader
        style={{ position: "absolute", top: "40%" }}
        type="BallTriangle"
        color="#000"
        height={150}
        width={150}
      />
    </div>
  ) : (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* <MDBox mb={2} /> */}
      <Header loading={loading}></Header>
      <MDButton
        style={{ marginLeft: 30 }}
        onClick={logout}
        variant="gradient"
        color="error"
      >
        Log out
      </MDButton>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
