import moment from "moment";
import { actionTypes } from "../../constants/ActionTypes";

const initialState = {
  userData: {},
  isUserSignedIn: false,
  authToken: "",
  lastAPIResponse: 200,
  lastAPICalled: "",
  isConnected: true,
  isGuest: true,
  showLoginPopUp: false,
  location: {},
  deviceId: "",
  loadedAt: "",
  userPhone: "",
  appTrackingPermission: true,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        loadedAt: moment(),
        authToken: action.payload.token,
      };
    case actionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        userData: { ...action.payload.userData },
      };
    case actionTypes.UPDATE_SIGNIN_STATUS:
      return {
        ...state,
        isUserSignedIn: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
